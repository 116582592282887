import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import CreateCampaignForm from './Components/Forms/Form';
import { Container } from 'reactstrap';
import CampaignsTable from './Components/Tables/Table';
import {
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_EMPTY,
  GET_CAMPAIGNS,
  GET_PROJECTS_LIST,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_EMPTY,
} from '../../../../../store/application/actionType';
import { Constant } from '../../../../Helpers/constant';

const Campaigns = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentSelectedDetails, setCurrentSelectedDetails] = useState(null);

  const [isShowCampaignTable, setIsShowCampaignTable] = useState(true);
  const [isShowCreateCampaignForm, setIsShowCreateCampaignForm] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Projects List
  const { projectsList, projectsListLoading } = useSelector(state => ({
    projectsList: state?.ProjectsList?.data,
    projectsListLoading: state?.ProjectsList?.loading,
  }));

  // Campaigns List
  const { campaigns, campaignsLoading } = useSelector(state => ({
    campaigns: state?.GetCampaigns?.data,
    campaignsLoading: state?.GetCampaigns?.loading,
  }));

  // Create Campaign
  const { createCampaign, createCampaignLoading } = useSelector(state => ({
    createCampaign: state?.CreateCampaign?.data,
    createCampaignLoading: state?.CreateCampaign?.loading,
  }));

  // Update Campaign
  const { updateCampaign, updateCampaignLoading } = useSelector(state => ({
    updateCampaign: state?.UpdateCampaign?.data,
    updateCampaignLoading: state?.UpdateCampaign?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_CAMPAIGNS,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (projectsList === null) {
      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (createCampaign !== null) {
      dispatch({
        type: CREATE_CAMPAIGN_EMPTY,
      });
      setIsShowCampaignTable(true);
      setIsShowCreateCampaignForm(false);
      setCurrentSelectedDetails(null);

      dispatch({
        type: GET_CAMPAIGNS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [createCampaign]);

  useEffect(() => {
    if (updateCampaign !== null) {
      dispatch({
        type: UPDATE_CAMPAIGN_EMPTY,
      });
      setIsShowCampaignTable(true);
      setIsShowCreateCampaignForm(false);
      setCurrentSelectedDetails(null);

      dispatch({
        type: GET_CAMPAIGNS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [updateCampaign]);

  /* ---------------------------- CREATE CAMPAIGN FORM HANDLER ---------------------------- */
  const createNewCampaignHandler = () => {
    setIsShowCampaignTable(false);
    setIsShowCreateCampaignForm(true);
  };

  /* ---------------------------- ON SUBMIT CAMPAIGN HANDLER ---------------------------- */
  const onSubmitCampaignForm = (data, flag) => {
    if (flag === `New`) {
      dispatch({
        type: CREATE_CAMPAIGN,
        payload: {
          data: {
            ...data,
          },
        },
      });
    } else if (flag === `Update`) {
      dispatch({
        type: UPDATE_CAMPAIGN,
        payload: {
          data: {
            ...data,
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentSelectedDetails(null);

    dispatch({
      type: GET_CAMPAIGNS,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);

    if (params?.actionType === `UPDATE`) {
      setIsShowCampaignTable(false);
      setIsShowCreateCampaignForm(true);
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (projectsListLoading || createCampaignLoading || campaignsLoading || updateCampaignLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [projectsListLoading, createCampaignLoading, campaignsLoading, updateCampaignLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
      setCurrentSelectedDetails(null);
    } else if (title === `Campaign(s)`) {
      setIsShowCampaignTable(true);
      setIsShowCreateCampaignForm(false);
      setCurrentSelectedDetails(null);
    } else if (title === `Create Campaign`) {
      // do nothing
    }
  };

  return (
    <div className="page-content">
      {loading && <Loader />}
      <Container fluid className="position-relative">
        {isShowCampaignTable && (
          <>
            <BreadCrumb title="Campaign" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Campaign(s)`]} />
            <CampaignsTable
              campaigns={campaigns?.results}
              createNewCampaign={createNewCampaignHandler}
              totalData={campaigns?.count}
              pageHandler={paginationHandler}
              pageNumber={currentPageNumber}
              actionHandler={tableActionHandler}
            />
          </>
        )}

        {isShowCreateCampaignForm && (
          <>
            <BreadCrumb title="Campaign" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Campaign(s)`, `Create Campaign`]} />
            <CreateCampaignForm projects={projectsList} onSubmit={onSubmitCampaignForm} campaignData={currentSelectedDetails} />
          </>
        )}
      </Container>
    </div>
  );
};

export default Campaigns;
