import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { BreadCrumb, PreviewCardHeader } from '../../../../Common';
import Filter from './Components/UI/Filter';
import LedgerInfo from './Components/UI/Table';
import InfoPreview from './Components/UI/InfoPreview';
import CustomTable from './Components/UI/CustomTable';

const Ledger = () => {
  const history = useHistory();

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Surge Sphere`) {
      // do nothing
    } else if (title === `Ledger`) {
      // do nothing
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <BreadCrumb title="Ledger" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Surge Sphere`, `Ledger`]} />

        <Filter />

        <Row>
          <Col xxl={12}>
            <Card>
              <InfoPreview />
              <LedgerInfo />
              {/* <CustomTable /> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Ledger;
