import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';
import { BreadCrumb, Loader } from '../../../../Common';
import CreateRuleForm from './Components/Forms/Form';
import RulesTable from './Components/Tables/Table';
import { Constant } from '../../../../Helpers/constant';
import {
  CREATE_RULE,
  CREATE_RULE_EMPTY,
  GET_ATTRIBUTES_EMPTY,
  GET_CAMPAIGNS,
  GET_PROJECTS_LIST,
  GET_RULES,
  GET_SURGE_EVENTS_EMPTY,
  UPDATE_RULE,
  UPDATE_RULE_EMPTY,
} from '../../../../../store/application/actionType';

const Rules = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentSelectedDetails, setCurrentSelectedDetails] = useState(null);

  const [isShowRulesTable, setIsShowRulesTable] = useState(true);
  const [isShowCreateRuleForm, setIsShowCreateRuleForm] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Projects List
  const { projectsList, projectsListLoading } = useSelector(state => ({
    projectsList: state?.ProjectsList?.data,
    projectsListLoading: state?.ProjectsList?.loading,
  }));

  // Campaigns List
  const { campaigns, campaignsLoading } = useSelector(state => ({
    campaigns: state?.GetCampaigns?.data,
    campaignsLoading: state?.GetCampaigns?.loading,
  }));

  // Rules List
  const { rules, rulesLoading } = useSelector(state => ({
    rules: state?.GetRules?.data,
    rulesLoading: state?.GetRules?.loading,
  }));

  // Rules Update
  const { updateRule, updateRuleLoading } = useSelector(state => ({
    updateRule: state?.UpdateRule?.data,
    updateRuleLoading: state?.UpdateRule?.loading,
  }));

  // Rule Create
  const { createRule, createRuleLoading } = useSelector(state => ({
    createRule: state?.CreateRule?.data,
    createRuleLoading: state?.CreateRule?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_RULES,
      payload: {
        urlParam: {
          pageIndex: 0,
        },
      },
    });
    dispatch({
      type: GET_SURGE_EVENTS_EMPTY,
    });
    dispatch({
      type: GET_ATTRIBUTES_EMPTY,
    });
  }, [dispatch]);

  useEffect(() => {
    if (projectsList === null) {
      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: GET_CAMPAIGNS,
      payload: {
        urlParam: {
          pageIndex: 0,
          getAll: 'true',
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (updateRule !== null) {
      dispatch({
        type: UPDATE_RULE_EMPTY,
      });
      setIsShowRulesTable(true);
      setIsShowCreateRuleForm(false);

      dispatch({
        type: GET_RULES,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [updateRule]);

  useEffect(() => {
    if (createRule !== null) {
      dispatch({
        type: CREATE_RULE_EMPTY,
      });
      setIsShowRulesTable(true);
      setIsShowCreateRuleForm(false);

      dispatch({
        type: GET_RULES,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  }, [createRule]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_SURGE_EVENTS_EMPTY,
      });
      dispatch({
        type: GET_ATTRIBUTES_EMPTY,
      });
    };
  }, []);

  /* ---------------------------- CREATE CAMPAIGN FORM HANDLER ---------------------------- */
  const createNewRuleHandler = () => {
    setIsShowRulesTable(false);
    setIsShowCreateRuleForm(true);
  };

  /* ---------------------------- ON SUBMIT RULES HANDLER ---------------------------- */
  const onSubmitRulesForm = (data, flag) => {
    if (flag === `New`) {
      dispatch({
        type: CREATE_RULE,
        payload: {
          data: {
            ...data,
          },
        },
      });
    } else if (flag === `Update`) {
      dispatch({
        type: UPDATE_RULE,
        payload: {
          data: {
            ...data,
          },
        },
      });
    } else if (flag === `DISABLE`) {
      dispatch({
        type: UPDATE_RULE,
        payload: {
          data: {
            ...data,
            isActive: false,
          },
        },
      });
    } else if (flag === `ENABLE`) {
      dispatch({
        type: UPDATE_RULE,
        payload: {
          data: {
            ...data,
            isActive: true,
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentSelectedDetails(null);

    dispatch({
      type: GET_RULES,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedDetails(params?.details);

    if (params?.actionType === `UPDATE`) {
      setIsShowRulesTable(false);
      setIsShowCreateRuleForm(true);
    } else if (params?.actionType === `DISABLE`) {
      onSubmitRulesForm(params?.details, `DISABLE`);
    } else if (params?.actionType === `ENABLE`) {
      onSubmitRulesForm(params?.details, `ENABLE`);
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (projectsListLoading || rulesLoading || campaignsLoading || updateRuleLoading || createRuleLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [projectsListLoading, rulesLoading, campaignsLoading, updateRuleLoading, createRuleLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Rule(s)`) {
      setIsShowRulesTable(true);
      setIsShowCreateRuleForm(false);
    } else if (title === `Create Rule`) {
      // do nothing
    }
  };

  return (
    <div className="page-content">
      {loading && <Loader />}
      <Container fluid className="position-relative">
        {isShowCreateRuleForm && (
          <>
            <BreadCrumb title="Rules" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Rule(s)`, `Create Rule`]} />
            <CreateRuleForm campaigns={campaigns?.results} onSubmit={onSubmitRulesForm} ruleData={currentSelectedDetails} />
          </>
        )}

        {isShowRulesTable && (
          <>
            <BreadCrumb title="Rules" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Rule(s)`]} />
            <RulesTable
              rules={rules?.results}
              createNewRule={createNewRuleHandler}
              totalData={rules?.count}
              pageHandler={paginationHandler}
              pageNumber={currentPageNumber}
              actionHandler={tableActionHandler}
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default Rules;
