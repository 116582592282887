import React, { useEffect, useState } from 'react';
import { Card, Row, Col, CardBody, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardFooter } from 'reactstrap';

import { Grid, _ } from 'gridjs-react';
import { Constant } from '../../../../../../../../Helpers/constant';
import ReactPaginate from 'react-paginate';

const AttributesTable = ({ attributes, createNewAttribute, totalData, pageHandler, pageNumber, actionHandler }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [attributesData, setAttributesData] = useState([]);

  useEffect(() => {
    if (attributes?.length > 0) {
      setAttributesData(
        attributes?.map((attribute, index) => {
          return [
            `${pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            attribute?.attributeId || `NA`,
            attribute?.name || `NA`,
            attribute?.inputType || `NA`,
            attribute?.eventName || `NA`,
            attribute,
            attribute,
          ];
        }),
      );
    } else {
      setAttributesData([]);
    }
  }, [attributes]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `UPDATE`) {
      actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `DISABLE`) {
      actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `ENABLE`) {
      actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- STATUS COLOR HANDLER ---------------------------- */
  const statusColorHandler = status => {
    switch (status) {
      case true:
        return <span className="text-success">Active</span>;

      case false:
        return <span className="text-danger">Inactive</span>;

      default:
        return <span>Active</span>;
    }
  };

  return (
    <Card className="card-inner">
      <CardHeader className="d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Event Attribute</h4>
        <button className="btn btn-primary" onClick={createNewAttribute}>
          + Create New Event Attribute
        </button>
      </CardHeader>

      <CardBody>
        <Row>
          <Col md={12}>
            <div className="custom-striped-table">
              <Grid
                data={attributesData}
                columns={[
                  {
                    name: 'S.No.',
                    width: '50px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Attribute ID',
                    width: '100px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Attribute Name',
                    width: '100px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Attribute Input Type',
                    width: '120px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Attribute Event Name',
                    width: '150px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Attribute Status',
                    width: '120px',
                    formatter: cell => _(<span className="">{statusColorHandler(cell?.status)}</span>),
                  },
                  {
                    name: 'Action',
                    width: '80px',
                    formatter: cell =>
                      _(
                        <UncontrolledDropdown className="dropdown d-inline-block">
                          <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                            <i className="ri-more-fill align-middle"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <li
                              onClick={() => {
                                actionBtnHandler(`UPDATE`, cell);
                              }}
                            >
                              <DropdownItem className="edit-item-btn">
                                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                Edit Attribute
                              </DropdownItem>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li
                              onClick={() => {
                                actionBtnHandler(cell?.status ? `DISABLE` : `ENABLE`, cell);
                              }}
                            >
                              <DropdownItem className="remove-item-btn">
                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                {cell?.status ? `Disabled` : `Enabled`}
                              </DropdownItem>
                            </li>
                          </DropdownMenu>
                        </UncontrolledDropdown>,
                      ),
                  },
                ]}
                sort={true}
                // search={true}
                fixedHeader={true}
                height="400px"
                pagination={{ enabled: false, limit: 5 }}
                style={{
                  th: {
                    zIndex: '999',
                    textAlign: 'center',
                  },
                  td: {
                    position: 'relative',
                    textAlign: 'center',
                  },
                  li: {
                    color: 'blue',
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Row className="custom_pagination">
          <Col className="text-end">
            <ReactPaginate
              previousLabel={'previous'}
              forcePage={pageNumber}
              nextLabel={'next'}
              breakLabel={'...'}
              pageCount={Math.ceil(totalData / Constant.TABLE_PAGE_SIZE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={event => {
                pageHandler(event.selected);
              }}
              containerClassName={'pagination justify-content-center'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

// export default React.memo(
//     AttributesTable,
//     (prevProps, nextProps) => prevProps.data === nextProps.data,
// )
export default AttributesTable;
