import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Badge, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { formattedWithComma } from '../../../../../../Helpers/Helpers';

const Table = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const status = [`In-process`, `Ready`, `Failed`];

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: `${t('S.No.')}`,
          width: '70px',
          wrap: true,
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: `${t('File Name')}`,
          minWidth: '140px',
          wrap: true,
          selector: row => row.fileName,
        },
        {
          name: `${t('Generated At')}`,
          width: '140px',
          selector: row => row.generatedAt,
        },
        {
          name: `${t('Status')}`,
          width: '140px',
          selector: row => (
            <h5>
              <Badge
                color={`${row?.status === 'In-process' ? 'warning' : ''}${row?.status === 'Ready' ? 'info' : ''}${
                  row?.status === 'Failed' ? 'danger' : ''
                }`}
              >
                {row?.status}
              </Badge>
            </h5>
          ),
        },
        {
          name: `${t('Actions')}`,
          width: '120px',
          selector: row => (
            <div className="d-flex justify-content-center flex-column">
              <>
                {row?.orignalData?.status === 2 && (
                  <span
                    className="btn btn-sm btn-primary mb-1"
                    onClick={() => {
                      actionBtnHandler(`DOWNLOAD`, row?.orignalData);
                    }}
                  >
                    Download
                  </span>
                )}
              </>
            </div>
          ),
        },
      ];
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            fileName: data?.filename || `NA`,
            generatedAt: data?.createdAt ? moment(`${data?.createdAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            status: status[data?.status - 1],
            orignalData: data,
          };
        }),
      );
      setColumns(fetchColumns);
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `DOWNLOAD`) {
      props.actionHandler({
        downloadDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title={t('Downloads')} refreshBtn={props?.data?.length > 0 ? true : false} refreshHandler={props?.refreshHandler} />

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                      padding: '8px',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(String(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1))}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
