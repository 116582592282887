import { PublicClientApplication } from '@azure/msal-browser';

// authConfig.js
export const msalConfig = {
  auth: {
    clientId: '8920e02b-0402-4453-99bc-7b994e52a1d4', // Application (client) ID from the Azure portal
    authority: 'https://login.microsoftonline.com/ff49c438-c469-4c10-96f6-61f54df41c9b',
    // clientId: 'b6b46294-6f28-49d0-b858-383e246970c5', // Application (client) ID from the Azure portal
    // authority: 'https://login.microsoftonline.com/94ef96d0-bf8d-41fd-9c27-48a0e57a9063',
    redirectUri: `${window.location.origin}/`, // Dynamic redirect URI based on the current environment
  },
  cache: {
    cacheLocation: 'localStorage', // Enables the token to be stored in local storage
    storeAuthStateInCookie: false,
  },
};

export const loginRequestByAdib = {
  scopes: ['openid', 'profile', 'User.Read'],
};

export const msalInstanceByAdib = new PublicClientApplication(msalConfig);
