import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import cogoToast from 'cogo-toast';
import Flatpickr from 'react-flatpickr';
import { Card, CardBody, CardFooter, Col, Form, Input, Label, Row } from 'reactstrap';

import Address from '../Modals/Address';
import PickupModal from '../Modals/Pickup';
import { Constant } from '../../../../../../Helpers/constant';
import { isValidEmail } from '../../../../../../Helpers/Helpers';
import { Error, Loader, PreviewCardHeader } from '../../../../../../Common';
import { indianProjectsBaseURL, customHeaders } from '../../../../../../Config';
import DateUtils from '../../../../../../Helpers/DateUtils';
import {
  CREATE_MANUAL_BOOKING,
  CREATE_MANUAL_BOOKING_EMPTY,
  CREATE_UPLOAD_TICKET,
  CREATE_UPLOAD_TICKET_EMPTY,
  GET_ADMIN_BENEFIT_ITEM,
  GET_ADMIN_BENEFIT_ITEM_EMPTY,
  GET_AIRPORTS_LIST,
  GET_AIRPORTS_LIST_EMPTY,
  GET_BENEFIT_CATEGORY,
  GET_BENEFIT_CATEGORY_EMPTY,
  GET_BENEFIT_GROUP_ITEMS,
  GET_BENEFIT_GROUP_ITEMS_EMPTY,
  GET_MEMBERSHIP_QUOTA,
  GET_MEMBERSHIP_QUOTA_EMPTY,
  GET_USER_CARD_DATA,
  GET_USER_CARD_DATA_EMPTY,
  UPDATE_PROFILE,
  UPDATE_PROFILE_EMPTY,
} from '../../../../../../../store/actions';

const initialState = {
  email: '',
  name: '',
  mobileNo: '',
  someOneName: '',
  someOneEmail: '',
  someOneMobileNo: '',
  noOfPassenger: null,
  noOfBabySeat: null,
  noOfLuggage: null,
  pickUpDate: null,
  pickUpTime: '',
  cardName: null,
  bookingType: null,
  paymentType: null,
  pickAndDrop: null,
  pickupLocation: null,
  dropLocation: null,
  travelPurchaseAmount: '',
  ticketPurchaseDate: null,
  flightNo: '',
  terminalNo: '',
  terminalName: '',
  uploadTicket: '',
  remarks: '',
};

const paymentTypeOptions = [
  { value: 0, label: 'Booking with payment links' },
  { value: 1, label: 'Booking without payment links' },
];

const airportOptions = [
  { value: 'Airport Drop', label: 'Airport Drop' },
  { value: 'Airport Pickup', label: 'Airport Pickup' },
];

const ManualBooking = ({ setIsShowManualBooking }) => {
  const [isBookingForSomeone, setIsBookingForSomeone] = useState(false);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUserExist, setIsUserExist] = useState(null);
  const [addressModal, setAddressModal] = useState(false);
  const [state, setState] = useState(initialState);
  const [cardNameOptions, setCardNameOptions] = useState([]);
  const [cardAddress, setCardAddress] = useState([]);
  const [pickupLocationOptions, setPickupLocationOptions] = useState([]);
  const [dropLocationOptions, setDropLocationOptions] = useState([]);
  const [options, setOptions] = useState({ noOfLuggage: [], noOfBabySeat: [], noOfPassenger: [], tat: {} });
  const dispatch = useDispatch();
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isPickupModalOpen, setIsPickupModalOpen] = useState(false);
  const [addressOrAirportList, setAddressOrAirportList] = useState(null);
  const [labelPickup, setLabelPickup] = useState('');
  const [membership, setMembership] = useState(null);
  const [isPdfFormat, setIsPdfFormat] = useState(false);
  const [isValidateEmail, setIsValidateEmail] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Countries
  const { getBenefitCategory, getBenefitCategoryLoading } = useSelector(state => ({
    getBenefitCategory: state?.GetBenefitCategory?.data,
    getBenefitCategoryLoading: state?.GetBenefitCategory?.loading,
  }));

  // Card
  const { getUserCard, getUserCardLoading } = useSelector(state => ({
    getUserCard: state?.GetUserCardData?.data,
    getUserCardLoading: state?.GetUserCardData?.loading,
  }));

  const { getMembershipQuota, getMembershipQuotaLoading } = useSelector(state => ({
    getMembershipQuota: state?.GetMembershipQuota?.data,
    getMembershipQuotaLoading: state?.GetMembershipQuota?.loading,
  }));

  const { getBenefitConfig, getBenefitConfigLoading } = useSelector(state => ({
    getBenefitConfig: state?.GetBenefitGroupItemsList?.data,
    getBenefitConfigLoading: state?.GetBenefitGroupItemsList?.loading,
  }));

  const { getAirport, getAirportLoading } = useSelector(state => ({
    getAirport: state?.Airports?.data?.results,
    getAirportLoading: state?.Airports?.loading,
  }));

  const { createManualBooking, createManualBookingLoading } = useSelector(state => ({
    createManualBooking: state?.CreateManualBooking?.data,
    createManualBookingLoading: state?.CreateManualBooking?.loading,
  }));

  const { createUploadTicket, createUploadTicketLoading } = useSelector(state => ({
    createUploadTicket: state?.CreateUploadTicket?.data,
    createUploadTicketLoading: state?.CreateUploadTicket?.loading,
  }));

  const { getAdminBenefitItems, getAdminBenefitItemsLoading } = useSelector(state => ({
    getAdminBenefitItems: state?.GetAdminBenefitItems?.data?.results,
    getAdminBenefitItemsLoading: state?.GetAdminBenefitItems?.loading,
  }));

  useEffect(() => {
    return () => {
      dispatch({ type: CREATE_MANUAL_BOOKING_EMPTY });
      dispatch({ type: CREATE_UPLOAD_TICKET_EMPTY });
      dispatch({ type: GET_ADMIN_BENEFIT_ITEM_EMPTY });
      dispatch({ type: GET_AIRPORTS_LIST_EMPTY });
      dispatch({ type: GET_BENEFIT_CATEGORY_EMPTY });
      dispatch({ type: GET_BENEFIT_GROUP_ITEMS_EMPTY });
      dispatch({ type: GET_MEMBERSHIP_QUOTA_EMPTY });
      dispatch({ type: GET_USER_CARD_DATA_EMPTY });
      dispatch({ type: UPDATE_PROFILE_EMPTY });
    };
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };
    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        break;

      case 'email':
        updatedState = {
          ...state,
          email: value,
        };
        break;

      case 'mobileNo':
        updatedState = {
          ...state,
          mobileNo: value,
        };
        break;

      case 'someOneName':
        updatedState = {
          ...state,
          someOneName: value,
        };
        break;

      case 'someOneEmail':
        updatedState = {
          ...state,
          someOneEmail: value,
        };
        break;

      case 'someOneMobileNo':
        updatedState = {
          ...state,
          someOneMobileNo: value,
        };
        break;

      case 'travelPurchaseAmount':
        updatedState = {
          ...state,
          travelPurchaseAmount: value,
        };
        break;

      case 'ticketPurchaseDate':
        updatedState = {
          ...state,
          ticketPurchaseDate: value,
        };
        break;

      case 'flightNo':
        updatedState = {
          ...state,
          flightNo: value,
        };
        break;

      case 'terminalNo':
        updatedState = {
          ...state,
          terminalNo: value,
        };
        break;

      case 'terminalName':
        updatedState = {
          ...state,
          terminalName: value,
        };
        break;

      case 'pickUpTime':
        updatedState = {
          ...state,
          pickUpTime: value,
        };
        break;

      case 'remarks':
        updatedState = {
          ...state,
          remarks: value,
        };
        break;
      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = null;
    if (label === `noOfPassenger`) {
      if (state?.noOfBabySeat) {
        if (Number(state?.noOfBabySeat?.value) + Number(data?.value) <= options.noOfPassenger.length) {
          updatedState = {
            ...state,
            noOfPassenger: data,
          };
        } else {
          updatedState = {
            ...state,
            noOfPassenger: data,
            noOfBabySeat: { label: '0', value: '0' },
          };
        }
      } else {
        updatedState = {
          ...state,
          noOfPassenger: data,
          noOfBabySeat: { label: '0', value: '0' },
        };
      }
      setState(updatedState);
      //   validateErrors(updatedState, `validationCheck`);
    } else if (label === `noOfBabySeat`) {
      if (state?.noOfPassenger) {
        if (Number(state.noOfPassenger?.value) + Number(data?.value) <= options.noOfPassenger.length) {
          updatedState = {
            ...state,
            noOfBabySeat: data,
          };
        } else {
          updatedState = {
            ...state,
            noOfPassenger: { label: '0', value: '0' },
            noOfBabySeat: data,
          };
        }
      } else {
        updatedState = {
          ...state,
          noOfPassenger: null,
          noOfBabySeat: data,
        };
      }
      setState(updatedState);
    } else if (label === `noOfLuggage`) {
      updatedState = {
        ...state,
        noOfLuggage: data,
      };
      setState(updatedState);
    } else if (label === `cardName`) {
      updatedState = {
        ...state,
        cardName: data,
      };
      setState(updatedState);
    } else if (label === `bookingType`) {
      updatedState = {
        ...state,
        bookingType: data,
      };
      setState(updatedState);
    } else if (label === `paymentType`) {
      updatedState = {
        ...state,
        paymentType: data,
      };
      setState(updatedState);
    } else if (label === `pickAndDrop`) {
      updatedState = {
        ...state,
        pickAndDrop: data,
      };
      setState(updatedState);
    } else if (label === `pickupLocation`) {
      updatedState = {
        ...state,
        pickupLocation: data,
      };
      setState(updatedState);
    } else if (label === `dropLocation`) {
      updatedState = {
        ...state,
        dropLocation: data,
      };
      setState(updatedState);
    }
    validateErrors(`validationCheck`, updatedState);
  };

  const validateErrors = (flag, data = state) => {
    if (
      data?.name?.trim() !== '' &&
      data?.email?.trim() &&
      isValidEmail(data?.email?.trim()) &&
      data?.mobileNo?.trim() &&
      (!isBookingForSomeone ||
        (isBookingForSomeone && data?.someOneName?.trim() !== '' && data?.someOneEmail?.trim() && data?.someOneMobileNo?.trim())) &&
      data?.noOfPassenger !== null &&
      data?.noOfBabySeat !== null &&
      data?.noOfLuggage !== null &&
      data?.pickUpDate !== null &&
      data?.cardName !== null &&
      data?.bookingType !== null &&
      data?.paymentType !== null &&
      data?.pickAndDrop !== null &&
      data?.pickupLocation !== null &&
      data?.dropLocation !== null &&
      data?.travelPurchaseAmount?.trim() !== '' &&
      data?.pickUpTime?.trim() !== '' &&
      data?.ticketPurchaseDate !== null &&
      data?.flightNo?.trim() !== '' &&
      data?.terminalNo?.trim() !== '' &&
      data?.terminalName?.trim() !== '' &&
      data?.uploadTicket?.trim() !== ''
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;
    switch (flag) {
      case 'pickUpDate':
        updatedState = {
          ...state,
          pickUpDate: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        setState(updatedState);
        break;
      case 'ticketPurchaseDate':
        updatedState = {
          ...state,
          ticketPurchaseDate: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        setState(updatedState);
        break;
      default:
      // do nothing
    }
    validateErrors(`validationCheck`, updatedState);
  };

  const submitHandler = () => {
    setIsPdfFormat(false);
    if (validateErrors(`submissionCheck`)) {
      dispatch({
        type: CREATE_MANUAL_BOOKING,
        payload: {
          data: {
            process: 'initiate-booking',
            numberOfPassengers: state?.noOfPassenger?.value ?? '',
            numberOfBabySeats: state?.noOfBabySeat?.value ?? '',
            numberOfLuggages: state?.noOfLuggage?.value ?? '',
            paymentType: state?.paymentType?.value,
            bookingForSomeoneElse: isBookingForSomeone ? 1 : 0,
            playerDetails: isBookingForSomeone
              ? {
                  name: state.someOneName ?? '',
                  email: state.someOneEmail ?? '',
                  mobileNumber: state.someOneMobileNo ?? '',
                  countryCode: '',
                }
              : null, // Only if bookingForSomeoneElse is 1
            serviceType: {
              type: state.pickAndDrop?.value === 'Airport Pickup' ? 'pickp' : 'drop',
            },
            pickupLocation:
              state.pickAndDrop?.value === 'Airport Pickup'
                ? {
                    airportId: state?.pickupLocation?.data?.objectId ?? '',
                    nickName: state?.pickupLocation?.data?.nickName ?? '',
                    name: state?.pickupLocation?.data?.name ?? '',
                    address: state?.pickupLocation?.data?.address ?? '',
                    emirates: state?.pickupLocation?.data?.region ?? '',
                    region: state?.pickupLocation?.data?.subregion ?? '',
                    zipCode: state?.pickupLocation?.data?.zipCode ?? '',
                    latitude: '0',
                    longitude: '0',
                  }
                : {
                    objectId: state?.pickupLocation?.data?.id ?? '',
                    nickName: state?.pickupLocation?.data?.nickname ?? '',
                    name: state?.pickupLocation?.data?.nickname ?? '',
                    address: state?.pickupLocation?.data?.streetAddress ?? '',
                    emirates: state?.pickupLocation?.data?.region ?? '',
                    region: state?.pickupLocation?.data?.subregion ?? '',
                    zipCode: state?.pickupLocation?.data?.postalCode ?? '',
                    latitude: String(state?.pickupLocation?.data?.latitude ?? '0'),
                    longitude: String(state?.pickupLocation?.data?.longitude ?? '0'),
                  },
            dropLocation:
              state.pickAndDrop?.value === 'Airport Drop'
                ? {
                    airportId: state?.dropLocation?.data?.objectId ?? '',
                    nickName: state?.dropLocation?.data?.nickName ?? '',
                    name: state?.dropLocation?.data?.name ?? '',
                    address: state?.dropLocation?.data?.address ?? '',
                    emirates: state?.dropLocation?.data?.region ?? '',
                    region: state?.dropLocation?.data?.subregion ?? '',
                    zipCode: state?.dropLocation?.data?.zipCode ?? '',
                    latitude: '0',
                    longitude: '0',
                  }
                : {
                    objectId: state?.dropLocation?.data?.id ?? '',
                    nickName: state?.dropLocation?.data?.nickname ?? '',
                    name: state?.dropLocation?.data?.nickname ?? '',
                    address: state?.dropLocation?.data?.streetAddress ?? '',
                    emirates: state?.dropLocation?.data?.region ?? '',
                    region: state?.dropLocation?.data?.subregion ?? '',
                    zipCode: state?.dropLocation?.data?.postalCode ?? '',
                    latitude: String(state?.dropLocation?.data?.latitude ?? '0'),
                    longitude: String(state?.dropLocation?.data?.longitude ?? '0'),
                  },
            pickupDate: state.pickUpDate,
            pickupTime: state.pickUpTime,
            flightNumber: state.flightNo,
            terminalNumber: state.terminalNo,
            terminalName: state.terminalName,
            ticketPurchaseDate: state.ticketPurchaseDate,
            ticketPurchaseAmount: {
              value: Number(state?.travelPurchaseAmount ?? 0),
              currency: 'AED',
            },
            ticketUrl: state.uploadTicket,
            bookingDate: state?.pickUpDate ? moment(`${state?.pickUpDate} ${state?.pickUpTime}`, 'YYYY-MM-DD HH:mm').toISOString() : '',
            remarks: state?.remarks,
          },
          urlParam: {
            benefitConfigId: membership?.benefitConfigId,
            membershipId: membership?.membershipId,
            serviceId: getAdminBenefitItems?.[0]?.service?.id,
            playerId: isUserExist?.userId,
          },
        },
      });
    }
  };

  const searchUser = async () => {
    if (state.email && isValidEmail(state.email)) {
      setIsValidateEmail(false);
      setSearchLoading(true);
      const searchedUser = await axios({
        method: `POST`,
        url: `${indianProjectsBaseURL}/user/v1/bulk-user`,
        headers: {
          Authorization: Constant?.CLIENT_TOKEN,
          ...customHeaders,
          'X-Requested-By': 'one-admin',
        },
        data: {
          include: ['address'],
          email: state?.email,
        },
      });

      if (searchedUser?.data?.users?.length === 0) {
        cogoToast.error('User not found');
        setSearchLoading(false);
        return;
      }

      dispatch({
        type: GET_BENEFIT_CATEGORY,
        payload: {
          urlParams: {
            userId: searchedUser?.data?.users[0]?.userId,
          },
        },
      });

      dispatch({
        type: GET_USER_CARD_DATA,
        payload: {
          urlParams: {
            userId: searchedUser?.data?.users[0]?.userId,
          },
        },
      });
      setSearchLoading(false);
      setIsUserExist(searchedUser?.data?.users?.[0]);
      setState({
        ...state,
        name: `${searchedUser?.data?.users?.[0]?.firstName ?? ''} ${searchedUser?.data?.users?.[0]?.lastName ?? ''}`,
        mobileNo: searchedUser?.data?.users[0]?.phoneNumber,
      });
      setCardAddress(searchedUser?.data?.users[0]?.addresses);
    } else {
      setIsValidateEmail(true);
    }
  };

  useEffect(() => {
    if (getMembershipQuota?.results === null || getMembershipQuota?.results?.length === 0) cogoToast.error('Membership not found');
  }, [getMembershipQuota]);

  const cardSelectHandler = async (label, data) => {
    if (getMembershipQuota?.results?.length === 0 || getMembershipQuota?.results === null) {
      cogoToast.error("You can't select card without a membership");
      return;
    }
    const membership = getMembershipQuota?.results?.find(membership => {
      return data?.data?.membershipId === membership?.membershipId;
    });
    setMembership(membership);
    if (membership !== null) {
      dispatch({
        type: GET_BENEFIT_GROUP_ITEMS,
        payload: {
          urlParam: {
            benefitConfigId: membership?.benefitConfigId,
          },
        },
      });
      membership?.type === 0
        ? inputSelectHandler('bookingType', { value: 0, label: 'Complimentory' })
        : inputSelectHandler('bookingType', { value: 0, label: 'Discounted' });
      setState({
        ...state,
        bookingType: membership?.type === 0 ? { value: 0, label: 'Complimentory' } : { value: 1, label: 'Discounted' },
        cardName: data,
      });
    } else {
      setState({
        ...state,
        cardName: data,
      });
    }
  };

  useEffect(() => {
    if (getBenefitCategory !== null) {
      const category = getBenefitCategory?.results?.find(benefitCategory => {
        return benefitCategory?.bookingType?.typeId === Constant.BOOKING_TYPES.pickupAndDropBookings.objectId;
      });
      if (category) {
        dispatch({
          type: GET_MEMBERSHIP_QUOTA,
          payload: {
            urlParams: {
              categoryId: category?.objectId,
              userId: isUserExist?.userId,
            },
          },
        });
      } else {
        cogoToast.error('Membership Quota not found');
      }
    }
  }, [getBenefitCategory]);

  useEffect(() => {
    if (getUserCard !== null) {
      setCardNameOptions(
        getUserCard?.results?.map(card => {
          return {
            value: card?.name,
            label: card?.name,
            data: card,
          };
        }),
      );
    }
  }, [getUserCard]);

  useEffect(() => {
    if (getBenefitConfig !== null) {
      if (getBenefitConfig?.results?.[0]?.airports != null) {
        const airportIds = getBenefitConfig?.results?.[0]?.airports?.map(airport => airport.id);
        dispatch({
          type: GET_AIRPORTS_LIST,
          payload: {
            urlParam: { objectIds: airportIds },
          },
        });
        if (getBenefitConfig?.results?.[0]?.carTypes?.length > 0) {
          setOptions({
            noOfBabySeat: Array.from({ length: getBenefitConfig?.results?.[0]?.carTypes?.[0]?.babySeats + 1 }, (_, index) => ({
              value: index.toString(),
              label: index.toString(),
            })),
            noOfPassenger: Array.from({ length: getBenefitConfig?.results?.[0]?.carTypes?.[0]?.passengers + 1 }, (_, index) => ({
              value: (index + 1).toString(),
              label: (index + 1).toString(),
            })),
            noOfLuggage: Array.from({ length: getBenefitConfig?.results?.[0]?.carTypes?.[0]?.luggages + 1 }, (_, index) => ({
              value: index.toString(),
              label: index.toString(),
            })),
            tat: getBenefitConfig?.results?.[0]?.tat,
          });
        }
      }

      dispatch({
        type: GET_ADMIN_BENEFIT_ITEM,
        payload: {
          urlParam: {
            benefitConfigId: getBenefitConfig?.results?.[0]?.objectId,
            userId: isUserExist?.userId,
          },
        },
      });
    }
  }, [getBenefitConfig]);

  useEffect(() => {
    if (createUploadTicket != null) {
      setState({ ...state, uploadTicket: createUploadTicket?.fileUrl });
      cogoToast.success('File Uploaded Successfully');
    }
  }, [createUploadTicket]);

  useEffect(() => {
    if (
      searchLoading ||
      getBenefitCategoryLoading ||
      getUserCardLoading ||
      getMembershipQuotaLoading ||
      getAirportLoading ||
      getBenefitConfigLoading ||
      createUploadTicketLoading ||
      createManualBookingLoading ||
      getAdminBenefitItemsLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    searchLoading,
    getBenefitCategoryLoading,
    getUserCardLoading,
    getMembershipQuotaLoading,
    getAirportLoading,
    getBenefitConfigLoading,
    createUploadTicketLoading,
    createManualBookingLoading,
    getAdminBenefitItemsLoading,
  ]);

  const toggleAddressModal = () => {
    setIsAddressModalOpen(!isAddressModalOpen);
  };

  const togglePickupModal = () => {
    setIsPickupModalOpen(!isPickupModalOpen);
    setAddressOrAirportList(null);
  };

  const handlePickup = (flag, data) => {
    setState({ ...state, [flag]: data });
    setIsPickupModalOpen(false);
  };

  const handleOpenAddress = () => {
    setIsAddressModalOpen(true);
    setIsPickupModalOpen(false);
  };

  useEffect(() => {
    if (createManualBooking != null) {
      dispatch({ type: CREATE_MANUAL_BOOKING_EMPTY });
      dispatch({ type: CREATE_UPLOAD_TICKET_EMPTY });
      dispatch({ type: GET_ADMIN_BENEFIT_ITEM_EMPTY });
      dispatch({ type: GET_AIRPORTS_LIST_EMPTY });
      dispatch({ type: GET_BENEFIT_CATEGORY_EMPTY });
      dispatch({ type: GET_BENEFIT_GROUP_ITEMS_EMPTY });
      dispatch({ type: GET_MEMBERSHIP_QUOTA_EMPTY });
      dispatch({ type: GET_USER_CARD_DATA_EMPTY });
      dispatch({ type: UPDATE_PROFILE_EMPTY });
      setIsShowManualBooking(false);
    }
  }, [createManualBooking]);

  const handleAddress = data => {
    setState(
      state.pickAndDrop === 'Airport Pickup'
        ? {
            ...state,
            dropLocation: {
              label: data?.nickname,
              value: data?.nickname,
              data: data,
            },
          }
        : {
            ...state,
            pickupLocation: {
              label: data?.nickname,
              value: data?.nickname,
              data: data,
            },
          },
    );
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        data: {
          addresses: [
            {
              nickname: data?.nickname,
              streetAddress: data?.streetAddress,
              region: data?.region?.value,
              subregion: data?.subregion?.value,
              postalCode: data?.postalCode,
            },
          ],
        },
        urlParams: {
          userId: isUserExist?.userId,
        },
      },
    });
    state.pickAndDrop === 'Airport Pickup'
      ? setDropLocationOptions([
          ...dropLocationOptions,
          {
            label: data?.nickname,
            value: data?.nickname,
            data: data,
          },
        ])
      : setPickupLocationOptions([
          ...pickupLocationOptions,
          {
            label: data?.nickname,
            value: data?.nickname,
            data: data,
          },
        ]);
    setIsAddressModalOpen(false);
  };

  const fileHandler = async e => {
    const fileData = e?.target?.files[0];
    if (fileData) {
      if (fileData.type !== 'application/pdf') {
        setIsPdfFormat(true);
        return;
      }
      const formData = new FormData();
      formData.append('file', fileData);
      formData.append('purpose', 'flightTicket');
      formData.append('userId', isUserExist?.userId ?? '');
      dispatch({
        type: CREATE_UPLOAD_TICKET,
        payload: {
          data: formData,
        },
      });
    } else {
      console.log('No file selected');
    }
  };

  return (
    <>
      {loading && <Loader />}
      {isAddressModalOpen && <Address isOpen={isAddressModalOpen} toggle={toggleAddressModal} onConfirm={handleAddress} />}
      {isPickupModalOpen && (
        <PickupModal
          isOpen={isPickupModalOpen}
          toggle={togglePickupModal}
          airportAndAddress={addressOrAirportList}
          handlePickup={handlePickup}
          handleOpenAddress={handleOpenAddress}
          label={labelPickup}
        />
      )}
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Create Manual Booking" />

            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Label className="form-check-label">Booking for Myself</Label>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Email
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <div className="input-group">
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state.email}
                            autoComplete="off"
                            disabled={false}
                          />
                          {!isUserExist && (
                            <span className="input-group-text cursor-pointer btn btn-primary" onClick={searchUser}>
                              Show Details
                            </span>
                          )}
                          {isUserExist && (
                            <span
                              className="input-group-text cursor-pointer btn btn-primary"
                              onClick={() => {
                                setState(initialState);
                                setIsUserExist(null);
                              }}
                            >
                              Clear
                            </span>
                          )}
                        </div>
                        {isValidationShow && state?.email === '' && <Error text="Please enter email" />}
                        {isValidationShow && state?.email?.trim() !== `` && !isValidEmail(state?.email) && (
                          <Error text="Please enter correct email" />
                        )}
                        {isValidateEmail && state?.email?.trim() === '' && <Error text="Please enter email" />}
                        {isValidateEmail && state?.email?.trim() !== '' && !isValidEmail(state?.email) && <Error text="Please enter correct email" />}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Full Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.name}
                          autoComplete="off"
                          disabled={true}
                        />
                        {isValidationShow && state?.name === '' && <Error text="Please enter name" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Mobile No
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="mobileNo"
                          name="mobileNo"
                          type="number"
                          onWheel={e => e.target.blur()}
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.mobileNo}
                          autoComplete="off"
                          disabled={false}
                        />
                        {isValidationShow && state?.mobileNo === '' && <Error text="Please enter mobile no" />}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <div className="form-check form-switch">
                          <Input
                            role="switch"
                            type="checkbox"
                            id="has-parent-category"
                            name="has-parent-category"
                            className="form-check-input"
                            checked={isBookingForSomeone}
                            onChange={() => {
                              setIsBookingForSomeone(!isBookingForSomeone);
                            }}
                          />
                          <Label className="form-check-label" htmlFor="has-parent-category">
                            Booking for someone else?
                          </Label>
                        </div>
                      </div>
                    </Col>
                    {isBookingForSomeone && (
                      <>
                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                          <div className="mb-3">
                            <Label htmlFor="name" className="form-label">
                              Name
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <Input
                              id="someOneName"
                              name="someOneName"
                              type="text"
                              className="form-control"
                              onChange={inputChangeHandler}
                              value={state.someOneName}
                              autoComplete="off"
                              disabled={false}
                            />
                            {isValidationShow && state?.someOneName === '' && <Error text="Please enter name" />}
                          </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                          <div className="mb-3">
                            <Label htmlFor="name" className="form-label">
                              Email
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <Input
                              id="someOneEmail"
                              name="someOneEmail"
                              type="email"
                              className="form-control"
                              onChange={inputChangeHandler}
                              value={state.someOneEmail}
                              autoComplete="off"
                              disabled={false}
                            />
                            {isValidationShow && state?.someOneEmail === '' && <Error text="Please enter email" />}
                          </div>
                        </Col>
                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                          <div className="mb-3">
                            <Label htmlFor="name" className="form-label">
                              Mobile Number
                              <span className="lbl_mandatory"> *</span>
                            </Label>
                            <Input
                              id="someOneMobileNo"
                              name="someOneMobileNo"
                              type="text"
                              className="form-control"
                              onChange={inputChangeHandler}
                              value={state.someOneMobileNo}
                              autoComplete="off"
                              disabled={false}
                            />
                            {isValidationShow && state?.someOneMobileNo === '' && <Error text="Please enter mobile no" />}
                          </div>
                        </Col>
                      </>
                    )}

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="cardName" className="form-label">
                          Card Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Select
                          id="cardName"
                          name="cardName"
                          value={state.cardName}
                          options={cardNameOptions}
                          onChange={data => cardSelectHandler('cardName', data)}
                          isDisabled={false}
                        />
                        {isValidationShow && state?.cardName === null && <Error text="Please select card name" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="bookingType" className="form-label">
                          Booking Type
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Select
                          id="bookingType"
                          name="bookingType"
                          value={state.bookingType}
                          options={[{ value: '1', label: '1' }]}
                          onChange={data => inputSelectHandler('bookingType', data)}
                          isDisabled={true}
                        />
                        {isValidationShow && state?.bookingType === null && <Error text="Please select booking type" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="paymentType" className="form-label">
                          Payment Type
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Select
                          id="paymentType"
                          name="paymentType"
                          value={state.paymentType}
                          options={paymentTypeOptions}
                          onChange={data => inputSelectHandler('paymentType', data)}
                          isDisabled={false}
                        />
                        {isValidationShow && state?.paymentType === null && <Error text="Please select payment type" />}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="noOfPassenger" className="form-label">
                          No Of Passengers
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Select
                          id="noOfPassenger"
                          name="noOfPassenger"
                          value={state.noOfPassenger}
                          options={options.noOfPassenger}
                          onChange={data => {
                            inputSelectHandler('noOfPassenger', data);
                          }}
                          isDisabled={state.cardName === null}
                        />
                        {isValidationShow && state?.noOfPassenger === null && <Error text="Please select no of passenger" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="noOfBabySeat" className="form-label">
                          No Of BabySeat
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Select
                          id="noOfBabySeat"
                          name="noOfBabySeat"
                          value={state.noOfBabySeat}
                          options={options.noOfBabySeat}
                          onChange={data => inputSelectHandler('noOfBabySeat', data)}
                          isDisabled={state.cardName === null}
                        />
                        {isValidationShow && state?.noOfBabySeat === null && <Error text="Please select no of baby seat" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="noOfLuggage" className="form-label">
                          No Of Luggage
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Select
                          id="noOfLuggage"
                          name="noOfLuggage"
                          value={state.noOfLuggage}
                          options={options.noOfLuggage}
                          onChange={data => inputSelectHandler('noOfLuggage', data)}
                          isDisabled={state.cardName === null}
                        />
                        {isValidationShow && state?.noOfLuggage === null && <Error text="Please select no of luggage" />}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={12} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="pickAndDrop" className="form-label">
                          Pickup/Drop
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Select
                          id="pickAndDrop"
                          name="pickAndDrop"
                          value={state.pickAndDrop}
                          options={airportOptions}
                          onChange={data => {
                            if (data?.label === 'Airport Drop') {
                              setPickupLocationOptions(
                                cardAddress?.map(address => {
                                  return {
                                    value: address?.streetAddress,
                                    label: address?.nickname,
                                    data: address,
                                  };
                                }),
                              );
                              setDropLocationOptions(
                                getAirport?.map(airport => {
                                  return {
                                    value: airport?.objectId,
                                    label: airport?.nickName,
                                    data: airport,
                                  };
                                }),
                              );
                            } else {
                              setDropLocationOptions(
                                cardAddress?.map(address => {
                                  return {
                                    value: address?.streetAddress,
                                    label: address?.nickname,
                                    data: address,
                                  };
                                }),
                              );
                              setPickupLocationOptions(
                                getAirport?.map(airport => {
                                  return {
                                    value: airport?.objectId,
                                    label: airport?.nickName,
                                    data: airport,
                                  };
                                }),
                              );
                            }
                            setState({ ...state, pickupLocation: null, dropLocation: null, pickAndDrop: data });
                          }}
                          isDisabled={state.cardName === null}
                        />
                        {isValidationShow && state?.pickAndDrop === null && <Error text="Please select airport options" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={12} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="pickupLocation" className="form-label">
                          Pickup Location
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Select
                            id="pickupLocation"
                            name="pickupLocation"
                            value={state.pickupLocation}
                            options={pickupLocationOptions}
                            onMenuOpen={() => {
                              if (!isPickupModalOpen) {
                                setIsPickupModalOpen(true);
                                setLabelPickup('pickupLocation');
                                setAddressOrAirportList({
                                  isAddress: state.pickAndDrop?.value === 'Airport Drop',
                                  list: pickupLocationOptions,
                                  selectedValue: state.pickupLocation,
                                });
                              }
                            }}
                            isDisabled={state.pickAndDrop === null}
                            styles={{
                              container: provided => ({
                                ...provided,
                                flex: 1,
                                marginRight: '8px',
                              }),
                            }}
                          />
                        </div>
                        {isValidationShow && state?.pickupLocation === null && <Error text="Please select pickup location" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={12} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="dropLocation" className="form-label">
                          Drop Location
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Select
                            id="dropLocation"
                            name="dropLocation"
                            value={state.dropLocation}
                            options={dropLocationOptions}
                            onChange={data => inputSelectHandler('dropLocation', data)}
                            isDisabled={state.pickAndDrop === null}
                            onMenuOpen={() => {
                              if (!isPickupModalOpen) {
                                setIsPickupModalOpen(true);
                                setLabelPickup('dropLocation');
                                setAddressOrAirportList({
                                  isAddress: state.pickAndDrop?.value === 'Airport Pickup',
                                  list: dropLocationOptions,
                                  selectedValue: state.dropLocation,
                                });
                              }
                            }}
                            styles={{
                              container: provided => ({
                                ...provided,
                                flex: 1,
                                marginRight: '8px',
                              }),
                            }}
                          />
                        </div>
                        {isValidationShow && state?.dropLocation === null && <Error text="Please select booking type" />}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Pickup Date
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Flatpickr
                          className="form-control"
                          onChange={date => datePickHandler(`pickUpDate`, date)}
                          value={state?.pickUpDate}
                          options={{
                            dateFormat: 'Y-m-d',
                            minDate: new Date().fp_incr(DateUtils.getMinDateInDays(options?.tat?.delayedMinTat, options?.tat?.delayedMinTatUnit)),
                            maxDate: new Date().fp_incr(DateUtils.getMaxDateInDays(options?.tat?.delayedMaxTat, options?.tat?.delayedMaxTatUnit)),
                          }}
                        />
                        {isValidationShow && state?.pickUpDate === null && <Error text="Please select pickup date" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Pickup Time
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          type="time"
                          name="pickUpTime"
                          className="form-control"
                          style={{ width: '100% important' }}
                          value={state.pickUpTime}
                          onChange={inputChangeHandler}
                        />
                        {isValidationShow && state?.pickUpTime === '' && <div className="text-danger">Please select pickup time</div>}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="travelPurchaseAmount" className="form-label">
                          Travel Purchase Amount
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="travelPurchaseAmount"
                          name="travelPurchaseAmount"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.travelPurchaseAmount}
                          autoComplete="off"
                          disabled={false}
                        />
                        {isValidationShow && state?.travelPurchaseAmount === '' && <Error text="Please enter travel purchase amount" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Ticket Purchase Date
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Flatpickr
                          className="form-control"
                          onChange={date => datePickHandler(`ticketPurchaseDate`, date)}
                          value={state?.ticketPurchaseDate}
                          options={{
                            dateFormat: 'Y-m-d',
                            maxDate: 'today',
                          }}
                        />
                        {isValidationShow && state?.ticketPurchaseDate === null && <Error text="Please select travel purchase date" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Flight No
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="flightNo"
                          name="flightNo"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.flightNo}
                          autoComplete="off"
                          disabled={false}
                        />
                        {isValidationShow && state?.flightNo === '' && <Error text="Please enter a flight no" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="terminalNo" className="form-label">
                          Terminal No
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="terminalNo"
                          name="terminalNo"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.terminalNo}
                          autoComplete="off"
                          disabled={false}
                        />
                        {isValidationShow && state?.terminalNo === '' && <Error text="Please enter a terminal no" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="terminalName" className="form-label">
                          Terminal Name
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="terminalName"
                          name="terminalName"
                          type="text"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.terminalName}
                          autoComplete="off"
                          disabled={false}
                        />
                        {isValidationShow && state?.terminalName === '' && <Error text="Please enter a terminal name" />}
                      </div>
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="uploadTicket" className="form-label">
                          Upload Ticket
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="uploadTicket"
                          name="uploadTicket"
                          type="file"
                          className="form-control"
                          onChange={fileHandler}
                          autoComplete="off"
                          disabled={false}
                        />
                        <small>Upload Ticket (Pdf format only)</small>
                        <br />
                        {isValidationShow && state?.uploadTicket === '' && <Error text="Please upload a file" />}
                        {isPdfFormat && state?.uploadTicket === '' && <Error text="Please upload a file in pdf format" />}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label htmlFor="remarks" className="form-label">
                          Remarks
                        </Label>
                        <Input type="textarea" name="remarks" id="remarks" rows="3" value={state?.remarks} onChange={inputChangeHandler} />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary overlay-disable" onClick={submitHandler} disabled={!isUserExist}>
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
            {addressModal && <Address addressModal={addressModal} setAddressModal={setAddressModal} />}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ManualBooking;
