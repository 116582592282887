import React, { useEffect, useState } from 'react';

import { Card, Col, Row, CardBody, Input, Label, CardHeader, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';
import { Constant } from '../../../../../../../../Helpers/constant';
import { Error } from '../../../../../../../../Common';

const attributeInputTypes = [
  {
    label: `Numeric`,
    value: `int`,
  },
  {
    label: `Alphanumeric`,
    value: `string`,
  },
  {
    label: `Date Picker`,
    value: `date`,
  },
];

const attributeEventStatus = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];

const type = [
  { value: '1', label: 'Event' },
  { value: '2', label: 'Transaction' },
];

const CreateAttributeForm = ({ projects, events, onSubmit, attributeData, onBack }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    objectId: '',
    programName: Constant?.CURRENT_SELECTED_PROGRAM?.name,
    programId: {
      value: Constant?.CURRENT_SELECTED_PROGRAM?.id,
      label: Constant?.CURRENT_SELECTED_PROGRAM?.name,
    },
    eventName: '',
    eventId: '',
    name: '',
    type: { value: '1', label: 'Event' },
    clientId: '',
    attributeId: '',
    description: '',
    inputType: '',
    inputKeyword: '',
    status: { value: true, label: 'Active' },
    isMandatory: true,
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (attributeData !== null) {
      const tempEventData = events?.filter(event => event?.eventName === attributeData?.eventName);
      setState({
        objectId: attributeData?.objectId,
        programName: attributeData?.programName,
        programId: {
          value: attributeData?.programId,
          label: attributeData?.programName,
        },
        eventName: tempEventData?.map(event => ({
          value: `${event?.objectId}`,
          label: `${event?.eventName}`,
        }))?.[0],
        eventId: attributeData?.eventId,
        name: attributeData?.name,
        type: attributeData?.type,
        clientId: attributeData?.clientId,
        attributeId: attributeData?.attributeId,
        description: attributeData?.description,
        inputType: attributeInputTypes?.filter(iType => iType?.value === attributeData?.inputType)?.[0],
        inputKeyword: attributeData?.inputKeyword,
        status: attributeEventStatus?.filter(status => status?.value === attributeData?.status)?.[0],
        isMandatory: attributeData?.isMandatory,
      });
    }
  }, [attributeData]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        break;

      case 'attributeId':
        updatedState = {
          ...state,
          // attributeId: value,
        };
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        break;

      case 'inputKeyword':
        updatedState = {
          ...state,
          inputKeyword: value,
        };
        break;

      case 'isMandatory':
        updatedState = {
          ...state,
          isMandatory: e.target.checked,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    switch (label) {
      case 'inputType':
        updatedState = {
          ...state,
          inputType: data,
        };
        break;

      case 'eventName':
        updatedState = {
          ...state,
          eventName: data,
        };
        break;

      case 'attribute-status':
        updatedState = {
          ...state,
          status: data,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.eventName !== `` && data?.name?.trim() !== `` && data?.inputType !== `` && data?.inputKeyword?.trim() !== '') {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        ...state,
        programId: state?.programId?.value,
        inputType: state?.inputType?.value ?? ``,
        status: state?.status?.value ?? ``,
        eventId: state?.eventName?.value ?? ``,
        eventName: state?.eventName?.label ?? ``,
        type: state?.type?.value,
      };

      if (attributeData === null) {
        onSubmit(
          {
            ...payload,
          },
          'New',
        );
      } else {
        onSubmit(
          {
            ...payload,
          },
          `Update`,
        );
      }
    }
  };

  return (
    <>
      <Card className="card-inner">
        {/* <button onClick={() => console.log(state)}>Get state</button> */}
        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Attribute details</h4>
        </CardHeader>
        <CardBody className="text-black">
          <Row>
            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="programName" className="form-label">
                  Program Name
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="programName"
                  name="programName"
                  value={state?.programName}
                  disabled
                  //   onChange={inputChangeHandler}
                />
              </div>
            </Col>

            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="eventName" className="form-label">
                  Event Name
                </Label>
                <Select
                  id="eventName"
                  name="eventName"
                  value={state?.eventName}
                  options={events?.map(event => ({
                    value: `${event?.objectId}`,
                    label: `${event?.eventName}`,
                  }))}
                  onChange={data => inputSelectHandler(`eventName`, data)}
                />
                {isValidationShow && state?.eventName === `` && <Error text="Please enter event name" />}
              </div>
            </Col>

            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="type" className="form-label">
                  Attribute Type
                </Label>
                <Input type="text" className="form-control" id="type" name="type" value={state?.type?.label} disabled onChange={inputChangeHandler} />
              </div>
            </Col>

            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Attribute Name
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter an attribute name"
                  value={state?.name}
                  onChange={inputChangeHandler}
                />
                {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter attribute name" />}
              </div>
            </Col>

            {attributeData !== null && (
              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="attributeId" className="form-label">
                    Attribute ID
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="attributeId"
                    name="attributeId"
                    disabled={true}
                    value={state?.attributeId}
                    onChange={inputChangeHandler}
                    placeholder="This name will be display in the report"
                  />
                </div>
              </Col>
            )}

            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="validityTatValue" className="form-label">
                  Attribute Description
                  <span className="text-muted">(Optional)</span>
                </Label>
                <Input type="textarea" name="description" id="description" rows={5} value={state?.description} onChange={inputChangeHandler} />
              </div>
            </Col>

            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="inputType" className="form-label">
                  Attribute Input Type
                </Label>
                <Select
                  id="inputType"
                  name="inputType"
                  value={state?.inputType}
                  options={attributeInputTypes}
                  onChange={data => inputSelectHandler(`inputType`, data)}
                />
                {isValidationShow && state?.inputType === `` && <Error text="Please select attribute input type" />}
              </div>
            </Col>

            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="inputKeyword" className="form-label">
                  Input Attribute Keyword
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="inputKeyword"
                  name="inputKeyword"
                  value={state?.inputKeyword}
                  onChange={inputChangeHandler}
                />
                {isValidationShow && state?.inputKeyword === `` && <Error text="Please select attribute keyword" />}
              </div>
            </Col>

            <Col md={3}>
              <div className="mb-3">
                <Label className="form-label">
                  Mandatory &nbsp;
                  <span className="fw-medium cursor-pointer" id="is_override_allow">
                    <i className="ri-information-fill"></i>
                  </span>
                  <UncontrolledTooltip placement="top" target="is_override_allow">
                    Please select if this attribute is mandatory or non mandatory
                  </UncontrolledTooltip>
                </Label>
                <div className="d-flex align-items-center ms-4">
                  <span className="text-muted me-3">No</span>
                  <div className="form-switch d-inline-block ">
                    <Input
                      className="form-check-input code-switcher form-switch-md cursor-pointer"
                      type="checkbox"
                      id="isMandatory"
                      name="isMandatory"
                      checked={state?.isMandatory}
                      onChange={inputChangeHandler}
                    />
                  </div>
                  <span className="ms-3">Yes</span>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="card-inner">
        <CardHeader className="d-flex text-black">
          <h4 className="card-title mb-0 flex-grow-1">Set Attribute Status</h4>
          <Col md={4}>
            <Select
              id="attribute-status"
              name="attribute-status"
              value={state?.status}
              options={attributeEventStatus}
              onChange={data => inputSelectHandler(`attribute-status`, data)}
            />
          </Col>
        </CardHeader>
      </Card>

      <Row>
        <Col md={6} className="mb-5">
          <div className="text-start">
            <button type="submit" className="btn btn-soft-dark" onClick={onBack}>
              <span className="fs-10">
                <i className="ri-arrow-left-line"></i>
              </span>
              &nbsp;Back
            </button>
          </div>
        </Col>
        <Col md={6} className="mb-5">
          <div className="text-end">
            <button type="submit" className="btn btn-primary" onClick={submitHandler}>
              {attributeData !== null ? `Update Attribute` : `Create Attribute`}
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CreateAttributeForm;
