import React from 'react';

import {
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Row,
  CardFooter,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../Common';

const Filter = () => {
  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="customer-name" className="form-label">
                          Select Program <span className="lbl_mandatory">*</span>
                        </Label>
                        <select className="form-control">
                          <option>All</option>
                          <option>Axis Magnus</option>
                          <option>Axis Bharat</option>
                          <option>Axis</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label htmlFor="customer-name" className="form-label">
                          Customer Segment <span className="lbl_mandatory">*</span>
                        </Label>
                        <select className="form-control">
                          <option>All</option>
                          <option>Silver</option>
                          <option>Gold</option>
                          <option>Platinum</option>
                        </select>
                      </div>
                    </Col>
                    {/* <Col md={2}>
                                            <div className="mb-3">
                                                <Label htmlFor="customer-name" className="form-label">
                                                    Date Range
                                                </Label>
                                                <Input
                                                    id="customer-name"
                                                    name="customer-name"
                                                    type="text"
                                                    placeholder=""
                                                    className="form-control"
                                                //   onChange={inputChangeHandler}
                                                //   value={filter?.customerDetails?.name}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={2}>
                                            <div className="mb-3">
                                                <Label htmlFor="customer-name" className="form-label">
                                                    Transaction Status
                                                </Label>
                                                <Input
                                                    id="customer-name"
                                                    name="customer-name"
                                                    type="text"
                                                    placeholder=""
                                                    className="form-control"
                                                //   onChange={inputChangeHandler}
                                                //   value={filter?.customerDetails?.name}
                                                />
                                            </div>
                                        </Col> */}
                    <Col md={1}>
                      <div className="mb-3">
                        <Label htmlFor="customer-name" className="form-label">
                          &nbsp;
                        </Label>
                        <Input
                          id="customer-name"
                          name="customer-name"
                          type="submit"
                          value="search"
                          placeholder=""
                          className="btn btn-primary"
                          //   onChange={inputChangeHandler}
                          //   value={filter?.customerDetails?.name}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
