import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Form, Row } from 'reactstrap';
import {
  CREATE_USER_REGISTRATION_FILE_UPLOAD,
  CREATE_USER_REGISTRATION_FILE_UPLOAD_EMPTY,
  GET_SAMPLE_FILE_LIST,
  GET_SAMPLE_FILE_LIST_EMPTY,
} from '../../../../../../../store/actions';
import { Loader } from '../../../../../../Common';
import cogoToast from 'cogo-toast';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { hasPermission } from '../../../../../../Helpers/Helpers';

const FileUploadCard = () => {
  const [files, setFiles] = useState({ USER_REGISTRATION: null, EVENT: null, TRANSACTION: null });
  const [errors, setErrors] = useState({});
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
  const [loading, setLoading] = useState(false);
  const [downloadSampleLoading, setDownloadSampleLoading] = useState(false);

  console.log('file type', files);

  const dispatch = useDispatch();
  const uploadState = useSelector(state => state.UserRegistration);

  const { uploadUserRegistration, uploadUserRegistrationLoading } = useSelector(state => ({
    uploadUserRegistration: state?.UserRegistration?.data,
    uploadUserRegistrationLoading: state?.UserRegistration?.loading,
  }));

  let sampleFile;
  const { getSampleFile, getSampleFileLoading } = useSelector(state => ({
    getSampleFile: state?.GetSampleFile?.data,
    getSampleFileLoading: state?.GetSampleFile?.loading,
  }));
  sampleFile = getSampleFile?.data?.sample_file;

  useEffect(() => {
    if (getSampleFile !== null) {
      window.open(getSampleFile?.data?.sample_file, `_self`);
      dispatch({
        type: GET_SAMPLE_FILE_LIST_EMPTY,
      });
    }
  }, [getSampleFile]);

  useEffect(() => {
    if (uploadUserRegistrationLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [uploadUserRegistrationLoading]);
  useEffect(() => {
    if (getSampleFileLoading) {
      setDownloadSampleLoading(true);
    } else {
      setDownloadSampleLoading(false);
    }
  }, [getSampleFileLoading]);

  useEffect(() => {
    return () => {
      dispatch({
        type: CREATE_USER_REGISTRATION_FILE_UPLOAD_EMPTY,
      });
    };
  }, []);

  useEffect(() => {
    if (uploadUserRegistration !== null) {
      setFiles({
        USER_REGISTRATION: null,
        EVENT: null,
        TRANSACTION: null,
      });
      setErrors({});
      cogoToast.success('File Uploaded Successfully');
      dispatch({
        type: CREATE_USER_REGISTRATION_FILE_UPLOAD_EMPTY,
      });
    }
  }, [uploadUserRegistration]);

  const handleFileUpload = (event, type) => {
    const input = event.target; // Reference to the input element
    const uploadedFile = input.files[0];
    if (!uploadedFile) return;
    console.log('uploaded checking', uploadedFile);
    const allowedExtensions = ['xlsx', 'xls', 'csv'];
    const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      setErrors(prev => ({
        ...prev,
        [type]: 'Invalid file type. Only Excel and CSV files are allowed.',
      }));
      input.value = ''; // Clear the input value
      return;
    }
    if (uploadedFile.size > MAX_FILE_SIZE) {
      setErrors(prev => ({
        ...prev,
        [type]: 'File size exceeds 10MB.',
      }));
      input.value = ''; // Clear the input value
      return;
    }
    // If valid, clear errors and set the file details
    setErrors(prev => ({ ...prev, [type]: '' }));
    setFiles(prev => ({
      ...prev,
      [type]: {
        file: uploadedFile,
        name: uploadedFile.name,
        size: (uploadedFile.size / 1024).toFixed(2) + ' KB', // Convert size to KB
      },
    }));
    input.value = ''; // Clear the input value after processin
    console.log(`${type} file selected:`, uploadedFile);
  };

  const handleDownloadClick = type => {
    dispatch({
      type: GET_SAMPLE_FILE_LIST,

      payload: {
        data: {
          type: type,
        },
      },
    });
  };

  const handleButtonClick = type => {
    if (!files[type]) {
      console.error(`No valid file selected for ${type}.`);
      return;
    }

    console.log(`File submitted for ${type}:`, files[type]);
    console.log('checkingtypesss', files[type].file);
    const formData = new FormData();
    formData.append('file', files[type]?.file);
    formData.append('type', type);
    console.log('checkformdata', formData);

    dispatch({
      type: CREATE_USER_REGISTRATION_FILE_UPLOAD,

      payload: {
        data: formData,
      },
    });
  };

  const triggerFileInput = type => {
    document.getElementById(`${type}FileInput`).click();
  };

  const uploadSection = (label, type) => (
    <Col>
      <div className="dropzone dz-clickable" onClick={() => triggerFileInput(type)} style={{ cursor: 'pointer' }}>
        <div className="dz-message needsclick">
          <div className="mb-3">
            <i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
          </div>
          <h4>{`Upload ${label}`}</h4>
          <p style={{ fontSize: 'small' }}>Upload Excel or CSV files</p>
          <p style={{ fontSize: 'small' }}>Max file size 10MB</p>
        </div>
        <input id={`${type}FileInput`} type="file" style={{ display: 'none' }} accept=".xlsx, .xls, .csv" onChange={e => handleFileUpload(e, type)} />
      </div>
      {errors[type] && <p style={{ color: 'red', fontSize: 'small', textAlign: 'center' }}>{errors[type]}</p>}

      <div className="text-right" style={{ paddingTop: '5px', marginRight: '5px', textAlign: 'right' }}>
        {/* <a
          // href="/path-to-sample-file/sample-file.pdf" // Replace with actual file URL
          href={sampleFile} // Replace with actual file URL
          download
          style={{ textDecoration: 'underline !important', color: 'primary !important', cursor: 'pointer' }}
          onClick={() => handleDownloadClick(type)}
        >
          Download Sample File
        </a> */}

        {/* <button className="btn btn-link" onClick={() => handleDownloadClick(type)}>
          Download Sample File
        </button> */}

        <p className="fs-6 d-inline-block text-decoration-underline cursor-pointer mb-0" onClick={() => handleDownloadClick(type)}>
          Download Sample File
        </p>
      </div>
      {files?.[type] && (
        <div id="file-name-container">
          <p style={{ margin: 0, color: '#6f6f7d', fontSize: '14px' }}>{files[type].name}</p>
          <strong style={{ display: 'block', color: '#333', fontSize: '14px', marginTop: '4px' }}>{files[type].size}</strong>
        </div>
      )}
      <div className="">
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Button style={{ width: '200px', marginBottom: '20px' }} onClick={() => handleButtonClick(type)} disabled={!files[type]}>
            Submit
          </Button>
        </div>
      </div>
    </Col>
  );

  return (
    <>
      {loading && <Loader />}
      {downloadSampleLoading && <Loader />}
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Verify Voucher</h4>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  {hasPermission(PermissionConstants.ALL_PERMISSIONS.USER_REGISTRATION) && uploadSection('User Registration', 'USER_REGISTRATION')}
                  {hasPermission(PermissionConstants.ALL_PERMISSIONS.EVENT) && uploadSection('Events', 'EVENT')}
                  {hasPermission(PermissionConstants.ALL_PERMISSIONS.TRANSACTION) && uploadSection('Transaction Data', 'TRANSACTION')}
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FileUploadCard;
