import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Constant } from '../../../../../../Helpers/constant';
import moment from 'moment';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { hasPermission, isDateIsGreaterThanLast7Days } from '../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { TablePagination } from '../../../../../../Common';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';

const Table = props => {
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [isBulkActionShow, setIsBulkActionShow] = useState(false);
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');
  const [currentFilterBookingStatus, setCurrentFilterBookingStatus] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [columns, setColumns] = useState([]);
  const [clearRow, setClearRow] = useState(false);

  // Update Booking
  const { updateBookings } = useSelector(state => ({
    updateBookings: state?.UpdateBooking?.data,
  }));

  // Resend Mail
  const { resendMail } = useSelector(state => ({
    resendMail: state?.ResendMail?.data,
  }));

  useEffect(() => {
    getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));
    if (props?.data?.length > 0) {
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking };
        }),
      );
      setSelectedBookings([]);
      setClearRow(!clearRow);
    } else {
      setData([]);
      setSelectedBookings([]);
    }
    // if (!props?.appliedFilters?.statusRange) {
    //   setSelectedBookings([]);
    // }
    setCurrentFilterBookingStatus(props?.appliedFilters?.statusRange || []);
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          selector: row => row.serialNumber,
        },
        {
          name: 'Booking Id',
          width: '140px',
          wrap: true,
          cell: row => (
            <a className="cursor-pointer text-decoration-underline" style={{ color: '#3577f1' }} onClick={() => actionBtnHandler(`VIEW`, row?.data)}>
              {row?.bookingId}
            </a>
          ),
        },

        {
          name: 'Name',
          selector: row => row.userName,
          wrap: true,
        },
        {
          name: 'Email',
          selector: row => row.userEmail,
          width: '220px',
        },
        {
          name: 'Mobile No',
          selector: row => row.userMobile,
          wrap: true,
        },
        {
          name: 'Coupon Code',
          selector: row => row.coupon,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Membership ID',
          width: '150px',
          selector: row => row.membershipId,
          wrap: true,
        },

        {
          name: 'Created At',
          width: '200px',
          selector: row => row.createdAt,
          wrap: true,
        },
        {
          name: 'Updated At',
          width: '200px',
          selector: row => row.updatedAt,
          wrap: true,
        },
        {
          name: 'Booking Status',
          width: '150px',
          wrap: true,
          cell: row => (
            <span
              className={`booking_status badge break-word bg-${
                Constant.BOOKING_STATUSES.filter(status => status?.name === row.bookingStatus)[0]?.bgColor
              }`}
            >
              {row.bookingStatus}
            </span>
          ),
        },
      ];
      setColumns(fetchColumns);
      setData(
        bookingData?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;
          let confirmedAt = `NA`;
          data?.bookingModifyPersons?.forEach(element => {
            if (element?.bookingStatus === Constant?.BOOKING_STATUS?.CONFIRM) {
              confirmedAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
            }
          });
          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            bookingId: data?.bookingId,
            index: index,
            userName: data?.user?.name || `NA`,
            userEmail: data?.user?.email || `NA`,
            userMobile: data?.user?.mobile || `NA`,
            coupon: data?.couponCode || `NA`,
            membershipId: data?.membershipId || `NA`,
            createdAt: data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            updatedAt: data?.updatedAt ? moment(new Date(`${data?.updatedAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            bookingStatus: status,
            data: data,
          };
        }),
      );
      setTimeout(() => {
        colorizeSlaBreached();
      }, [100]);
    } else {
      setData([]);
      setColumns([]);
    }
  }, [bookingData]);

  useEffect(() => {
    setSelectedBookings([]);
  }, [updateBookings, resendMail]);

  const handleRowSelected = state => {
    const selectedBookingDetails = state?.selectedRows;
    if (selectedBookingDetails.length > 0) {
      setSelectedBookings(
        selectedBookingDetails.map(selectedBooking => {
          return selectedBooking.data;
        }),
      );
    } else {
      setSelectedBookings([]);
    }
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `EDIT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `VIEW`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `PROCESS`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CANCEL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `RESEND_EMAIL`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `CONFIRM_WITH_DATA`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- BULK UPDATE STATUSES HANDLER ---------------------------- */
  const bulkUpdateStatusHandler = flag => {
    if (flag === 'BULK_PROCESS') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_CONFIRM') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_CANCEL') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_UPDATE') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    } else if (flag === 'BULK_RESEND_MAIL') {
      props.actionHandler({
        bookingDetails: selectedBookings,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  setTimeout(() => {
    colorizeSlaBreached();
  }, [100]);

  /* ---------------------------- COLORIZE SLA BREACH ---------------------------- */
  function colorizeSlaBreached() {
    if (props?.data?.length > 0) {
      const slaBreachedTableIndexes = props?.data
        ?.map((bookingData, index) => {
          if (bookingData?.slaDetails?.slaBreached) {
            return index;
          }
        })
        .filter(data => data !== undefined);
      Array.from(document.querySelectorAll('.rdt_TableRow')).map((row, index) => {
        if (slaBreachedTableIndexes.includes(index)) {
          row.style.backgroundColor = `#ec7b65`;
          row.style.color = `#fff`;
        } else {
          row.style.backgroundColor = `#fff`;
          row.style.color = `black`;
        }
      });
    } else {
      Array.from(document.querySelectorAll('.rdt_TableRow')).map((row, index) => {
        row.style.backgroundColor = `#fff`;
        row.style.color = `black`;
      });
    }
  }

  /* ---------------------------- COUNTDOWN HANDLER ---------------------------- */
  const countdownFunc = index => {
    if (props?.data?.[index]?.slaDetails?.slaBreached === false && props?.data?.[index]?.slaDetails?.slaLimit === 0) {
      return;
    } else {
      const diffTime = new Date() - new Date(props?.data?.[index]?.slaDetails?.lastProcessedTime);

      const remainingMilisecond = props?.data?.[index]?.slaDetails?.slaLimit - diffTime;
      return <Countdown date={new Date().valueOf() + remainingMilisecond} renderer={data => renderer(data, index)}></Countdown>;
    }
  };

  const renderer = (data, index) => {
    if (data.completed) {
      const selectedRow = Array.from(document.querySelectorAll('.rdt_TableRow'))[index + 1];
      if (selectedRow) {
        selectedRow.style.backgroundColor = `#ec7b65`;
        selectedRow.style.color = `#fff`;
      }
    } else {
      return (
        <span>
          {data.hours}:{data.minutes}:{data.seconds}
        </span>
      );
    }
  };

  useEffect(() => {
    setIsButtonDisabled(isTimePassed());
  }, [selectedBookings]);

  const isTimePassed = () => {
    for (let i = 0; i < selectedBookings.length; i++) {
      if (isDateIsGreaterThanLast7Days(selectedBookings[i]?.bookingDateTime)) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{currentFilteredBookingTitle}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>

            {selectedBookings.length > 0 && hasPermission(PermissionConstants?.ALL_PERMISSIONS?.GOLF_BOOKING_UPDATE) && (
              <CardHeader>
                {currentFilterBookingStatus.some(status => status === Constant.BOOKING_STATUS.PENDING) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_PROCESS`)}
                    disabled={isButtonDisabled}
                  >
                    Process
                  </button>
                )}

                {currentFilterBookingStatus.some(status => status === Constant.BOOKING_STATUS.PROCESSED) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_CONFIRM`)}
                    disabled={isButtonDisabled}
                  >
                    Confirm
                  </button>
                )}

                {currentFilterBookingStatus.some(status =>
                  [Constant.BOOKING_STATUS.PENDING, Constant.BOOKING_STATUS.PROCESSED, Constant.BOOKING_STATUS.CONFIRM].includes(status),
                ) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_UPDATE`)}
                    disabled={isButtonDisabled}
                  >
                    Update
                  </button>
                )}

                {currentFilterBookingStatus.some(status =>
                  [
                    Constant.BOOKING_STATUS.PENDING,
                    Constant.BOOKING_STATUS.CONFIRM,
                    Constant.BOOKING_STATUS.CANCEL_BY_THRIWE,
                    Constant.BOOKING_STATUS.CANCELLED,
                  ].includes(status),
                ) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_RESEND_MAIL`)}
                    disabled={isButtonDisabled}
                  >
                    Resend Email
                  </button>
                )}

                {currentFilterBookingStatus.some(status =>
                  [Constant.BOOKING_STATUS.PENDING, Constant.BOOKING_STATUS.PROCESSED, Constant.BOOKING_STATUS.CONFIRM].includes(status),
                ) && (
                  <button
                    className="rounded-pill btn btn-primary btn-sm mx-1"
                    onClick={() => bulkUpdateStatusHandler(`BULK_CANCEL`)}
                    disabled={isButtonDisabled}
                  >
                    Cancel
                  </button>
                )}
              </CardHeader>
            )}

            {data?.length > 0 && (
              <CardBody>
                <DataTable
                  columns={columns ?? []}
                  data={data ?? []}
                  highlightOnHover
                  selectableRows={currentFilterBookingStatus.length === 1 ? true : false}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={(updateBookings !== null ? true : false) || (resendMail !== null ? true : false) || clearRow}
                  customStyles={{
                    headRow: {
                      style: {
                        borderBottom: '1px solid #ddd',
                        backgroundColor: '#f9fafb',
                        fontWeight: '600',
                      },
                    },
                    cells: {
                      style: {
                        border: '0.5px solid #ddd',
                        borderBottom: '0px solid #ddd',
                      },
                    },
                  }}
                />
              </CardBody>
            )}

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
