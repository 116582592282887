import { Col, Row } from 'reactstrap';

const BreadcrumbTrail = ({ title, navigation, navigationby }) => {
  return (
    <Row>
      <Col xs={12}>
        <div className="flex-grow-1 mb-4">
          <h1 className="fs-24 mb-1 text-primary">{title}</h1>
          <ol className="breadcrumb m-0">
            {navigation?.map((text: string, index: Number) => (
              <li key={`${index}`} className={`breadcrumb-item ${navigation?.length === Number(index) + 1 ? 'active' : ''}`}>
                <span className={`${navigation?.length !== Number(index) + 1 ? 'cursor-pointer' : ''}`} onClick={() => navigationby(text)}>
                  {text}
                </span>
              </li>
            ))}
          </ol>
        </div>
      </Col>
    </Row>
  );
};

export default BreadcrumbTrail;
