import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import cogoToast from 'cogo-toast';
import { customHeaders, thriweDiscoveryUrl } from '../../../../../Containers/Config/index.js';
import { Constant } from '../../../../../Containers/Helpers/constant.js';
import { CREATE_SURGE_WORKFLOW, CREATE_SURGE_WORKFLOW_ERROR, CREATE_SURGE_WORKFLOW_SUCCESS } from '../../../actionType.js';
import apiCall from '../../../apiCall.js';

function* createSurgeWorkflow({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/create-surge-workflow`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: CREATE_SURGE_WORKFLOW_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CREATE_SURGE_WORKFLOW_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_SURGE_WORKFLOW_ERROR, payload: error });
  }
}

export function* watchCreateSurgeWorkflow() {
  yield takeEvery(CREATE_SURGE_WORKFLOW, createSurgeWorkflow);
}

function* createSurgeWorkflowSaga() {
  yield all([fork(watchCreateSurgeWorkflow)]);
}

export default createSurgeWorkflowSaga;
