import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, ModalBody, Spinner } from 'reactstrap';
import { UPDATE_REFERRAL, UPDATE_REFERRAL_EMPTY } from '../../../../../../store/actions';

// import { CONFIRM_BOOKING_V2, CONFIRM_BOOKING_V2_EMPTY, CANCEL_BOOKING_V2, CANCEL_BOOKING_V2_EMPTY } from '../../../../../../../../store/actions';

const UpdateReferral = ({ data, onCloseHandler, updateType }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { validateReferral, validateReferralLoading } = useSelector((state: any) => ({
    validateReferral: state?.ValidateReferral?.data,
    validateReferralLoading: state?.ValidateReferral?.loading,
  }));

  useEffect(() => {
    if (validateReferral !== null) {
      onCloseHandler();
      return () => {
        dispatch({
          type: UPDATE_REFERRAL_EMPTY,
        });
      };
    }
  }, [validateReferral, dispatch, onCloseHandler]);

  const handleYesClick = () => {
    dispatch({
      type: UPDATE_REFERRAL,
      payload: {
        urlParams: {
          id: data?.ID,
          status: updateType === 'Approve' ? 'APPROVED' : 'REJECTED',
        },
      },
    });
  };

  return (
    <Modal isOpen={true} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-2 mx-sm-2">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-1 mb-0">Are you sure you want to {updateType} this referral this request?</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <Button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseHandler}>
            Close
          </Button>
          {validateReferralLoading ? (
            <Button color="primary" className="btn-load" outline>
              <span className="d-flex align-items-center">
                <Spinner size="sm" className="flex-shrink-0">
                  Loading...
                </Spinner>
                <span className="flex-grow-1 ms-2">Loading...</span>
              </span>
            </Button>
          ) : (
            <Button type="button" className="btn w-sm btn-primary" onClick={handleYesClick}>
              Sure
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateReferral;
