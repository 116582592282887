import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Input } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import CogoToast from 'cogo-toast'; // Importing CogoToast for error toast
import moment from 'moment';

const filterOptions = [
  {
    label: `Name`,
    value: 'user_name',
    data: {
      name: 'Name',
      type: 'string',
      key: 'user_name',
    },
  },
  {
    label: `Mobile Number`,
    value: `mobile_number`,
    data: {
      name: 'Mobile Number',
      type: 'string',
      key: 'mobile_number',
    },
  },
  {
    label: `Email`,
    value: `email`,
    data: {
      name: 'Email',
      type: 'string',
      key: 'email',
    },
  },
];

const initialState = {
  user_name: '',
  from_date: '',
  to_date: '',
};

const Filter = props => {
  const [filter, setFilter] = useState(initialState);
  const [filterType, setFilterType] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState([]); // Array to store applied filters

  const flatpickrRef = useRef(null);

  useEffect(() => {
    if (flatpickrRef.current) {
      const flatpickrInstance = flatpickrRef.current.flatpickr;

      flatpickrInstance.config.onOpen.push(() => {
        const calendarContainer = flatpickrInstance.calendarContainer;

        if (!calendarContainer.querySelector('.custom-options')) {
          const customOptions = document.createElement('div');
          customOptions.className = 'custom-options';
          Object.assign(customOptions.style, {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            margin: '0', // Remove extra spacing
            padding: '10px',
            backgroundColor: '#FFFFFF', // Background for better visibility
            position: 'absolute', // Allow positioning next to the calendar
            top: '0', // Align with the calendar's top
            right: 'calc(100% + 1px)', // Position it to the right of the calendar
            zIndex: '9999', // Ensure it appears above other elements
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
          });

          const options = ['Today', 'Last 7 Days', 'Last 14 Days', 'Custom Range'];

          options.forEach(option => {
            const button = document.createElement('button');
            Object.assign(button.style, {
              padding: '11px 10px',
              fontSize: '0.8rem',
              margin: '10px 0',
              backgroundColor: '#3F5189',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              width: '122px',
            });

            button.textContent = option;

            button.onmouseenter = () => (button.style.backgroundColor = '#5A6FA1');
            button.onmouseleave = () => (button.style.backgroundColor = '#3F5189');

            button.addEventListener('click', () => handleCustomRange(option, flatpickrInstance));
            customOptions.appendChild(button);
          });

          calendarContainer.parentNode.style.position = 'relative'; // Ensure parent allows positioning
          calendarContainer.appendChild(customOptions);
        }
      });
    }
  }, []);

  const inputChangeHandler = e => {
    const { value } = e.target;
    if (value?.trim()) {
      setFilter({
        ...filter,
        [filterType?.value]: value, // Apply trim on change
      });
    } else {
      setFilter({
        ...filter,
        [filterType?.value]: '',
      });
    }
  };

  const submitHandler = () => {
    const trimmedValue = filter[filterType?.value]?.trim(); // Trim input value before validation

    // General Empty Validation
    if (!trimmedValue) {
      CogoToast.error('Field cannot be empty'); // Show toast error
      return;
    }

    // Email Validation
    if (filterType?.value === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!trimmedValue || !emailRegex.test(trimmedValue)) {
        CogoToast.error('Please enter a valid email address'); // Show toast error
        return;
      }
    }

    // Mobile Number Validation
    if (filterType?.value === 'mobile_number') {
      if (!trimmedValue || !/^\d+$/.test(trimmedValue)) {
        CogoToast.error('Mobile number should contain only digits'); // Show toast error
        return;
      }
    }

    // Check if filter type already exists
    const updatedFilters = appliedFilters.filter(f => f.key !== filterType?.value);
    updatedFilters.push({
      key: filterType?.value,
      label: filterType?.label,
      value: trimmedValue,
    });

    setAppliedFilters(updatedFilters);

    // Reset filter field for the current type
    setFilter({ ...filter, [filterType?.value]: '' });

    // Transform appliedFilters into the desired format
    const filtersObject = updatedFilters.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});
    console.log('filtersObject', { ...filter, ...filtersObject });
    props?.filterHandler({ ...filter, ...filtersObject });
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      submitHandler();
    }
  };

  const removeFilter = key => {
    // Remove filter from appliedFilters and filter state
    const updatedFilters = appliedFilters.filter(f => f.key !== key);
    setAppliedFilters(updatedFilters);
    setFilter({ ...filter, [key]: '' });

    // Transform updatedFilters into the desired format
    const filtersObject = updatedFilters.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});

    // Call the filterHandler with the updated filters
    props?.filterHandler({ ...filter, ...filtersObject });
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    console.log('check', date[0], date[1]);

    switch (flag) {
      case 'createdAtRange':
        updatedState = {
          ...filter,
          from_date: moment(new Date(date[0])).format('Y-MM-DD'),
          to_date: moment(new Date(date[1])).format('Y-MM-DD'),
        };
        setFilter(updatedState);
        break;
    }
    if (date[0] && date[1]) {
      const filtersObject = appliedFilters.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {});
      props?.filterHandler({ ...updatedState, ...filtersObject });
    }
  };

  const handleCustomRange = (option, flatpickrInstance) => {
    let startDate = null;
    let endDate = null;

    switch (option) {
      case 'Today':
        startDate = endDate = new Date();
        break;
      case 'Last 7 Days':
        startDate = new Date(new Date().setDate(new Date().getDate() - 7));
        endDate = new Date();
        break;
      case 'Last 14 Days':
        startDate = new Date(new Date().setDate(new Date().getDate() - 14));
        endDate = new Date();
        break;
      default:
        return;
    }
    // Set selected range and close calendar
    let updatedState = null;
    updatedState = {
      ...filter,
      from_date: moment(startDate).format('Y-MM-DD'),
      to_date: moment(endDate).format('Y-MM-DD'),
    };

    setFilter(updatedState);
    flatpickrInstance.close();
    const filtersObject = appliedFilters.reduce((acc, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});
    props?.filterHandler({ ...updatedState, ...filtersObject });
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Select
            id="benefit-group-status"
            name="benefit-group-status"
            options={filterOptions}
            value={filterType}
            onChange={data => {
              setFilterType(data);
              setFilter({ ...filter, [data.value]: '' }); // Reset corresponding filter value
            }}
            styles={{
              container: base => ({
                ...base,
                width: '160px',
              }),
              menu: provided => ({
                ...provided,
                zIndex: 2,
              }),
            }}
          />

          <Input
            id="name"
            name="name"
            type={'text'}
            placeholder="Search"
            className="form-control"
            value={filter?.[filterType?.value] || ''}
            onChange={inputChangeHandler}
            autoComplete="off"
            disabled={filterType === null}
            style={{ width: '200px' }}
            onKeyPress={handleKeyPress}
          />

          <Flatpickr
            ref={flatpickrRef}
            className="form-control"
            onChange={date => datePickHandler(`createdAtRange`, date)}
            value={[filter?.from_date, filter?.to_date]}
            placeholder="Select Range"
            options={{
              mode: 'range',
              dateFormat: 'Y-m-d',
              maxDate: 'today',
            }}
            style={{ width: '150px' }}
          />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {appliedFilters.map(({ key, label, value }) => (
            <div
              key={key}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                background: 'rgb(271, 164, 216)',
                padding: '5px 3px',
                borderRadius: '4px',
              }}
            >
              <span>{`${value}`}</span>
              <button style={{ border: 'none', background: 'transparent', cursor: 'pointer' }} onClick={() => removeFilter(key)}>
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Filter;
