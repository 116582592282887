import { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { formattedWithComma, hasPermission } from '../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Facility name',
          selector: row => row?.facilityName,
          wrap: true,
          width: '250px',
        },
        {
          name: 'From date',
          selector: row => row?.fromDate,
          wrap: true,
          width: '180px',
        },
        {
          name: 'To date',
          selector: row => row?.toDate,
          wrap: true,
          width: '180px',
        },
        {
          name: 'Created At',
          selector: row => row?.createdAt,
          wrap: true,
          width: '180px',
        },
        {
          name: 'Remarks',
          selector: row => row?.remarks,
          minWidth: '200px',
          wrap: true,
        },
        {
          name: 'Actions',
          width: '130px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100">
              {(hasPermission(PermissionConstants?.ALL_PERMISSIONS?.FACILITY_BLOCKED_DATES) ||
                hasPermission(PermissionConstants?.ALL_PERMISSIONS?.BLOCK_DATE_MANAGEMENT)) &&
                (row?.data?.isBlockOn ? (
                  <span
                    className={'badge badge-danger bg-danger mb-1'}
                    // onClick={() => {
                    //   actionBtnHandler(`DELETE`, row?.data);
                    // }}
                  >
                    <div className="">Blocked</div>
                  </span>
                ) : (
                  <span className="badge badge-success bg-success  mb-1">Not Blocked</span>
                ))}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            facilityName: data?.name || `NA`,
            fromDate: data?.fromDate
              ? `${moment(new Date(`${data?.fromDate}`)).format('DD-MM-YYYY')}, ${moment(`${data?.fromTime}`, 'HH:mm').format('h:mm A')}`
              : 'NA',
            toDate: data?.toDate
              ? `${moment(new Date(`${data?.toDate}`)).format('DD-MM-YYYY')}, ${moment(`${data?.toTime}`, 'HH:mm').format('h:mm A')}`
              : 'NA',
            createdAt: data?.modifiedAt ? moment(new Date(`${data?.modifiedAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            remarks: data?.remarks || `NA`,
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `DELETE`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };
  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{`${props?.title}`}</h4>

              <span className="btn btn-success mb-1" onClick={props?.setIsBlockDatesModal}>
                <div className="me-2">
                  <i className="ri-add-line me-1 text-white text-opacity-75 fs-14 align-middle"></i>Block/Unblock facility
                </div>
              </span>
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
