import { useHistory } from 'react-router-dom';

import { Container } from 'reactstrap';

import Filter from './Components/UI/Filter';
import { BreadCrumb } from '../../../../Common';
import WalletAccordion from './Components/UI/WalletAccordion';

const Wallet = () => {
  const history = useHistory();

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Surge Sphere`) {
      // do nothing
    } else if (title === `Wallet`) {
      // do nothing
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <BreadCrumb title="Wallet" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Surge Sphere`, `Wallet`]} />
        {/* <UpdateWallet
                    filterHandler={() => console.log('hello')}
                    appliedFilters={null}
                    show={true}
                    onCloseClick={() => console.log(false)}
                /> */}

        <Filter />
        <WalletAccordion />
      </Container>
    </div>
  );
};

export default Wallet;
