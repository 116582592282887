import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Constant } from '../../../../Helpers/constant';
import Table from './Tables/Table';
import { GET_LEDGER, GET_LEDGER_EMPTY } from '../../../../../store/actions';
import BreadcrumbTrail from '../../../../Common/BreadcrumbTrail';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Loader } from '../../../../Common';
import Filter from './Filters/Filter';

const LedgerV2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Parse URL query parameters
  const { page = 1, perPageLimit = Constant.TABLE_PAGE_SIZE } = queryString.parse(location.search);

  const [currentPageNumber, setCurrentPageNumber] = useState(Number(page) - 1); // `page` is 1-based
  const [rowsPerPage, setRowsPerPage] = useState(Number(perPageLimit));
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [loading, setLoading] = useState(false);

  const { getLedger, getLedgerLoading } = useSelector((state: any) => ({
    getLedger: state?.GetLedger?.data?.data,
    getLedgerLoading: state?.GetLedger?.loading,
  }));

  useEffect(() => {
    // Sync state with URL
    const { page, perPageLimit } = queryString.parse(location.search);
    setCurrentPageNumber(Number(page || 1) - 1); // Default to 1-based page
    setRowsPerPage(Number(perPageLimit || Constant.TABLE_PAGE_SIZE));
  }, [location.search]);

  useEffect(() => {
    dispatch({
      type: GET_LEDGER,
      payload: {
        data: {
          offset: currentPageNumber * rowsPerPage,
          limit: rowsPerPage,
          ...currentlyAppliedFilters,
        },
      },
    });
  }, [currentlyAppliedFilters, currentPageNumber, rowsPerPage, dispatch]);

  useEffect(() => {
    if (getLedgerLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getLedgerLoading]);

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);

    const updatedParams = {
      page: currentPageNumber + 1, // Default to current page
      perPageLimit: rowsPerPage, // Default to current rowsPerPage
      ...currentParams, // Merge existing query parameters
      ...newParams, // Override with new parameters
    };

    const newUrl = queryString.stringify(updatedParams);

    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);
    dispatch({
      type: GET_LEDGER_EMPTY,
    });
    updateURLParams({ page: 1 });
  };

  const paginationHandler = pageNumber => {
    updateURLParams({ page: pageNumber + 1 });
  };

  const handleRowsPerPageChange = newRowsPerPage => {
    updateURLParams({ page: 1, perPageLimit: newRowsPerPage });
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid className="position-relative">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
            <BreadcrumbTrail title="Ledger" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Ledger`]} />
            <Filter filterHandler={filterSubmitHandler} />
          </div>

          <Table
            data={getLedger?.ledger}
            totalCount={getLedger?.pagination?.total}
            pageNumber={currentPageNumber}
            appliedFilters={currentlyAppliedFilters}
            limit={rowsPerPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handlePageChange={paginationHandler}
          />
        </Container>
      </div>
    </>
  );
};

export default LedgerV2;
