import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweDiscoveryUrl, thriweMarketPlaceUaeUrl, thriweMarketPlaceUrl } from '../../../../../Containers/Config';
import { Constant } from '../../../../../Containers/Helpers/constant';
import apiCall from '../../../apiCall';
import {
  CREATE_USER_REGISTRATION_FILE_UPLOAD,
  CREATE_USER_REGISTRATION_FILE_UPLOAD_SUCCESS,
  CREATE_USER_REGISTRATION_FILE_UPLOAD_ERROR,
} from '../../../actionType';

function* userRegistration({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/data-integration/fileupload`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        // 'Project-Code': 'GLOBE_TOYOTA',
        ...customHeaders,
      },
      isFormData: true,
      // form: payload?.form,
      data: payload?.data,
      // params: {
      //   ...payload?.urlParam,
      //   ...payload?.file?.File?.type,
      //   // pageSize: Constant?.TABLE_PAGE_SIZE,
      // },
    });

    if (response.status === 200) {
      yield put({
        type: CREATE_USER_REGISTRATION_FILE_UPLOAD_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CREATE_USER_REGISTRATION_FILE_UPLOAD_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_USER_REGISTRATION_FILE_UPLOAD_ERROR, payload: error });
  }
}

export function* watchUserRegistration() {
  yield takeEvery(CREATE_USER_REGISTRATION_FILE_UPLOAD, userRegistration);
}

function* getRegistratioSaga() {
  yield all([fork(watchUserRegistration)]);
}

export default getRegistratioSaga;
