import React, { useEffect, useState } from 'react';

import { Card, Col, Row, CardBody, Input, Label, CardHeader, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { Error } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';

const CreateCampaignForm = ({ projects, onSubmit, campaignData }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    programName: Constant?.CURRENT_SELECTED_PROGRAM?.name,
    programId: {
      value: Constant?.CURRENT_SELECTED_PROGRAM?.id,
      label: Constant?.CURRENT_SELECTED_PROGRAM?.name,
    },
    campaignName: '',
    displayName: '',
    description: '',
    startDate: '',
    endDate: '',
    statusCode: { value: `2`, label: `Active` },
    ruleOverride: false,
    isIndefiniteDate: false,
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (campaignData !== null) {
      setState({
        programName: campaignData?.programName,
        programId: {
          value: campaignData?.programId,
          label: campaignData?.programName,
        },
        campaignName: campaignData?.campaignName,
        displayName: campaignData?.displayName,
        description: campaignData?.description,
        startDate: campaignData?.startDate,
        endDate: campaignData?.endDate,
        statusCode: Constant?.campaignStatuses?.filter(status => parseInt(status?.value) === campaignData?.statusCode)[0],
        ruleOverride: campaignData?.ruleOverride,
        isIndefiniteDate: campaignData?.isIndefiniteDate,
        objectId: campaignData?.objectId,
      });
    }
  }, [campaignData]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'campaignName':
        updatedState = {
          ...state,
          campaignName: value,
        };
        break;

      case 'displayName':
        updatedState = {
          ...state,
          displayName: value,
        };
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        break;

      case 'ruleOverride':
        updatedState = {
          ...state,
          ruleOverride: e.target.checked,
        };
        break;

      case 'isIndefiniteDate':
        var myDate = new Date(state?.startDate || new Date().toISOString());
        myDate.setDate(myDate.getDate() + parseInt(366 * 3));

        updatedState = {
          ...state,
          isIndefiniteDate: e.target.checked,
          endDate: new Date(myDate).toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...state };

    switch (flag) {
      case 'startDate':
        updatedState = {
          ...state,
          startDate: new Date(date).toISOString(),
        };
        break;

      case 'endDate':
        updatedState = {
          ...state,
          endDate: new Date(date).toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    switch (label) {
      case 'campaign-status':
        updatedState = {
          ...state,
          statusCode: data,
        };
        break;

      case 'programId':
        updatedState = {
          ...state,
          programId: data,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.programId !== `` &&
      data?.campaignName !== `` &&
      data?.startDate?.trim() !== `` &&
      data?.endDate?.trim() !== `` &&
      new Date(data?.startDate?.trim()).getTime() < new Date(data?.endDate?.trim()).getTime()
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        ...state,
        programId: state?.programId?.value,
        statusCode: parseInt(state?.statusCode?.value),
      };

      if (campaignData === null) {
        onSubmit(
          {
            ...payload,
          },
          'New',
        );
      } else {
        onSubmit(
          {
            ...payload,
          },
          `Update`,
        );
      }
    }
  };

  return (
    <>
      <Card className="card-inner">
        {/* <button onClick={() => console.log(campaignData, state)}>get state</button> */}
        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Campaign details</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="validityTatValue" className="form-label">
                  Program Name
                </Label>
                <Select
                  id="programId"
                  name="programId"
                  isDisabled={true}
                  value={state?.programId}
                  options={projects?.results?.map(project => ({
                    value: project?.id,
                    label: project?.name,
                  }))}
                  onChange={data => inputSelectHandler(`programId`, data)}
                />
              </div>
            </Col>

            <Col sm={6}>
              <div className="mb-3">
                <Label htmlFor="campaignName" className="form-label">
                  Campaign Name
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="campaignName"
                  name="campaignName"
                  value={state?.campaignName}
                  onChange={inputChangeHandler}
                />
                {isValidationShow && state?.campaignName?.trim() === `` && <Error text="Please enter campaign name" />}
              </div>
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="displayName" className="form-label">
                  Campaign Display Name
                  <span className="text-muted">(Optional)</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="displayName"
                  name="displayName"
                  placeholder="This name will be displayed in the report"
                  value={state?.displayName}
                  onChange={inputChangeHandler}
                />
              </div>
            </Col>

            <Col sm={12}>
              <div className="mb-3">
                <Label htmlFor="description" className="form-label">
                  Description
                  <span className="text-muted">(Optional)</span>
                </Label>
                <Input type="textarea" name="description" id="description" rows="5" value={state?.description} onChange={inputChangeHandler} />
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="startDate" className="form-label">
                  Campaign Start Date
                </Label>
                <Flatpickr
                  className="form-control"
                  name="startDate"
                  id="startDate"
                  placeholder="Select date"
                  value={state?.startDate}
                  onChange={date => datePickHandler(`startDate`, date)}
                  options={{
                    minDate: 'today',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }}
                />
                {isValidationShow && state?.startDate?.trim() === `` && <Error text="Please enter start date" />}

                {isValidationShow &&
                  state?.startDate !== `` &&
                  state?.endDate !== `` &&
                  new Date(state?.startDate).getTime() > new Date(state?.endDate).getTime() && <Error text="Start date is invalid" />}
              </div>
            </Col>

            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="endDate" className="form-label d-flex justify-content-between">
                  <span> Campaign End Date</span>
                  <div className="form-check">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      id="isIndefiniteDate"
                      name="isIndefiniteDate"
                      checked={state?.isIndefiniteDate}
                      onChange={inputChangeHandler}
                    />
                    <Label className="form-check-label" for="isIndefiniteDate">
                      Set as Indefinite
                    </Label>
                  </div>
                </Label>
                <Flatpickr
                  className="form-control"
                  name="endDate"
                  id="endDate"
                  placeholder="Select date"
                  value={state?.endDate}
                  onChange={date => datePickHandler(`endDate`, date)}
                  options={{
                    minDate: 'today',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }}
                />
                {isValidationShow && state?.endDate?.trim() === `` && <Error text="Please enter end date" />}
              </div>
            </Col>

            <Col md={3}>
              <div className="mb-3">
                <Label className="form-label">
                  Allow rules schedule override{' '}
                  <span className="fw-medium cursor-pointer" id="is_override_allow">
                    <i className="ri-information-fill"></i>
                  </span>
                  <UncontrolledTooltip placement="top" target="is_override_allow">
                    If you select yes then rule configurations will override campaign configurations
                  </UncontrolledTooltip>
                </Label>
                <div className="d-flex align-items-center ms-4">
                  <span className="text-muted me-3">No</span>
                  <div className="form-switch d-inline-block ">
                    <Input
                      className="form-check-input code-switcher form-switch-md cursor-pointer"
                      type="checkbox"
                      id="ruleOverride"
                      name="ruleOverride"
                      checked={state?.ruleOverride}
                      onChange={inputChangeHandler}
                    />
                  </div>
                  <span className="ms-3">Yes</span>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="card-inner">
        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Set Campaign Status</h4>
          <Col md={4}>
            <Select
              id="campaign-status"
              name="campaign-status"
              value={state?.statusCode}
              options={Constant?.campaignStatuses}
              onChange={data => inputSelectHandler(`campaign-status`, data)}
            />
          </Col>
        </CardHeader>
      </Card>

      <Col md={12} className="mb-5">
        <div className="text-end">
          <button type="submit" className="btn btn-primary btn-lg" onClick={submitHandler}>
            {campaignData !== null ? `Update Campaign` : `Create Campaign`}
          </button>
        </div>
      </Col>
    </>
  );
};

export default CreateCampaignForm;
