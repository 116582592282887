import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweMarketPlaceUaeUrl, thriweMarketPlaceUrl } from '../../../../../Containers/Config';
import { Constant } from '../../../../../Containers/Helpers/constant';
import apiCall from '../../../apiCall';
import { GET_REFERRAL, GET_REFERRAL_ERROR, GET_REFERRAL_SUCCESS } from '../../../actionType';

function* getReferral({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweMarketPlaceUrl}/v1/admin/list-referral`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_REFERRAL_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_REFERRAL_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_REFERRAL_ERROR, payload: error });
  }
}

export function* watchGetReferral() {
  yield takeEvery(GET_REFERRAL, getReferral);
}

function* getReferralSaga() {
  yield all([fork(watchGetReferral)]);
}

export default getReferralSaga;
