import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweDiscoveryUrl, thriweMarketPlaceUrl } from '../../../../../Containers/Config/index.js';
import { Constant } from '../../../../../Containers/Helpers/constant.js';
import apiCall from '../../../apiCall';
import { LIST_RULE_TABLES, LIST_RULE_TABLES_ERROR, LIST_RULE_TABLES_SUCCESS } from './../../../actionType.js';

function* getListSurgeRuleTables({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/rules/list-schemas`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
        // pageSize: Constant?.TABLE_PAGE_SIZE,
      },
    });

    if (response.status === 200) {
      yield put({
        type: LIST_RULE_TABLES_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: LIST_RULE_TABLES_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: LIST_RULE_TABLES_ERROR, payload: error });
  }
}

export function* watchListSurgeRuleTables() {
  yield takeEvery(LIST_RULE_TABLES, getListSurgeRuleTables);
}

function* ListSurgeRuleTablesSaga() {
  yield all([fork(watchListSurgeRuleTables)]);
}

export default ListSurgeRuleTablesSaga;
