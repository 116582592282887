import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweDiscoveryUrl, thriweMarketPlaceUrl } from '../../../../../Containers/Config';
import { Constant } from '../../../../../Containers/Helpers/constant';
import { GET_VOUCHER_BOOKING, GET_VOUCHER_BOOKING_ERROR, GET_VOUCHER_BOOKING_SUCCESS } from '../../../actionType';
import apiCall from '../../../apiCall';

function* getVoucherBooking({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweMarketPlaceUrl}/v1/admin/voucher-validation/bookings`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParams,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_VOUCHER_BOOKING_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_VOUCHER_BOOKING_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_VOUCHER_BOOKING_ERROR, payload: error });
  }
}

export function* watchgetVoucherBooking() {
  yield takeEvery(GET_VOUCHER_BOOKING, getVoucherBooking);
}

function* getVoucherBookingSaga() {
  yield all([fork(watchgetVoucherBooking)]);
}

export default getVoucherBookingSaga;
