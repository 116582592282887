import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CustomTable from '../../../../../../../Common/Table/Table';
import { Constant } from '../../../../../../../Helpers/constant';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const Table = props => {
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookingData, setBookingData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serialNumber,
        },
        {
          name: 'Event Name',
          // width: '140px',
          cell: row => row?.name,
        },
        {
          name: 'Created At',
          // width: '140px',
          cell: row => row?.created_at,
        },
      ];
      setColumns(fetchColumns);

      setBookingData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props?.pageNumber * props?.limit + index + 1 - props?.limit}.`,
            name: data?.name,
            created_at: data?.created_at ? moment(new Date(`${data?.created_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            originalData: data,
          };
        }),
      );
    } else {
      setBookingData([]);
      setColumns([]);
    }
  }, [props?.data]);

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <h2 className="fw-semibold card-title mb-0 flex-grow-1">All Events</h2>
              <button type="button" className="btn btn-success waves-effect waves-light" onClick={() => history.push(`/surge/workflow`)}>
                Create Workflow
              </button>
            </CardHeader>

            <CardBody>
              <CustomTable
                columns={columns ?? []}
                data={bookingData ?? []}
                totalCount={props.totalCount}
                limit={props.limit}
                handleRowsPerPageChange={props.handleRowsPerPageChange}
                currentPage={props.pageNumber}
                handlePageChange={props.handlePageChange}
                indexType={Constant.INDEX_TYPE.ONE_BASED}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
