import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import cogoToast from 'cogo-toast';
import { customHeaders, thriweDiscoveryUrl, thriweMarketPlaceUrl } from '../../../../../Containers/Config/index.js';
import { Constant } from '../../../../../Containers/Helpers/constant.js';
import apiCall from '../../../apiCall.js';
import { CREATE_SURGE_RULE, CREATE_SURGE_RULE_ERROR, CREATE_SURGE_RULE_SUCCESS } from '../../../actionType.js';

function* createSurgeRule({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/create-rule`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      cogoToast.success(response?.data?.message);
      yield put({
        type: CREATE_SURGE_RULE_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({
        type: CREATE_SURGE_RULE_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: CREATE_SURGE_RULE_ERROR, payload: error });
  }
}

export function* watchCreateSurgeRule() {
  yield takeEvery(CREATE_SURGE_RULE, createSurgeRule);
}

function* createSurgeRuleSaga() {
  yield all([fork(watchCreateSurgeRule)]);
}

export default createSurgeRuleSaga;
