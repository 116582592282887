import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import { GET_REFERRAL, GET_REFERRAL_EMPTY } from '../../../../../store/actions';
import { Loader } from '../../../../Common';
import BreadcrumbTrail from '../../../../Common/BreadcrumbTrail';
import { Constant } from '../../../../Helpers/constant';
import Filter from './Filters/Filter';
import UpdateReferral from './Modals/UpdateReferral';
import Table from './Tables/Table';

const Refferal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Parse URL query parameters
  const { page = 1, perPageLimit = Constant.TABLE_PAGE_SIZE } = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(page);
  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);
  const [currentSelectedReferralDetails, setCurrentSelectedReferralDetails] = useState(null);
  const [updateType, setUpdateType] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(Number(perPageLimit));

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Refferral
  const { getReferral, getReferralLoading } = useSelector((state: any) => ({
    getReferral: state?.GetReferral?.data,
    getReferralLoading: state?.GetReferral?.loading,
  }));

  // Validate Referral
  const { validateReferral } = useSelector((state: any) => ({
    validateReferral: state?.ValidateReferral?.data,
  }));

  useEffect(() => {
    const { page, perPageLimit } = queryString.parse(location.search);
    setCurrentPageNumber(Number(page || 1)); // Default to 1-based page
    setRowsPerPage(Number(perPageLimit || Constant.TABLE_PAGE_SIZE));
  }, [location.search]);

  useEffect(() => {
    dispatch({
      type: GET_REFERRAL,
      payload: {
        urlParams: {
          page: currentPageNumber,
          pageSize: rowsPerPage,
          ...currentlyAppliedFilters,
        },
      },
    });
  }, [validateReferral, currentlyAppliedFilters, currentPageNumber, rowsPerPage, dispatch]);

  useEffect(() => {
    if (validateReferral !== null) {
      dispatch({
        type: GET_REFERRAL_EMPTY,
      });
      updateURLParams({ page: 1 });
    }
  }, [validateReferral]);

  useEffect(() => {
    if (getReferralLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getReferralLoading]);

  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);
    dispatch({
      type: GET_REFERRAL_EMPTY,
    });
    updateURLParams({ page: 1 });
  };

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);
    console.log('currentParams', currentParams, newParams);
    const updatedParams = {
      page: currentPageNumber, // Default to current page
      perPageLimit: rowsPerPage, // Default to current rowsPerPage
      ...currentParams, // Merge existing query parameters
      ...newParams, // Override with new parameters
    };

    const newUrl = queryString.stringify(updatedParams);

    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedReferralDetails(params?.referralDetails);
    if (params?.actionType === `APPROVE`) {
      setIsReferralModalOpen(true);
      setUpdateType('Approve');
    } else if (params?.actionType === `REJECT`) {
      setIsReferralModalOpen(true);
      setUpdateType('Reject');
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    updateURLParams({ page: pageNumber });
  };

  const handleRowsPerPageChange = (newRowsPerPage, page) => {
    updateURLParams({ page: 1, perPageLimit: newRowsPerPage });
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {isReferralModalOpen && (
        <UpdateReferral onCloseHandler={() => setIsReferralModalOpen(false)} data={currentSelectedReferralDetails} updateType={updateType} />
      )}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  UI'S ---------------------------- */}{' '}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
            <BreadcrumbTrail title="Referral" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Referral`]} />
            <Filter filterHandler={filterSubmitHandler} />
          </div>
          <Table
            data={getReferral?.results}
            totalCount={getReferral?.count}
            pageNumber={currentPageNumber}
            actionHandler={tableActionHandler}
            appliedFilters={currentlyAppliedFilters}
            limit={rowsPerPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handlePageChange={paginationHandler}
            filterHandler={filterSubmitHandler}
          />
        </Container>
      </div>
    </>
  );
};

export default Refferal;
