import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CustomTable from '../../../../../../../Common/Table/Table';
import { Constant } from '../../../../../../../Helpers/constant';
import moment from 'moment';
import CreateRuleModal from '../../../../Event/CreateEvent/Components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_SURGE_RULE_EMPTY } from '../../../../../../../../store/actions';

const Table = props => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  // CREATE SURGE RULE
  const { createSurgeRule, createSurgeRuleLoading } = useSelector((state: any) => ({
    createSurgeRule: state?.CreateSurgeRule?.data,
    createSurgeRuleLoading: state?.CreateSurgeRule?.loading,
  }));

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookingData, setBookingData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isCreateRuleModal, setIsCreateRuleModal] = useState(false);

  useEffect(() => {
    if (createSurgeRule !== null) {
      dispatch({
        type: CREATE_SURGE_RULE_EMPTY,
      });

      setIsCreateRuleModal(false);
    }
  }, [createSurgeRule]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serialNumber,
        },
        {
          name: 'Rule Name',
          // width: '250px',
          cell: row => row?.name,
        },
        {
          name: 'Table Name',
          width: '200px',
          cell: row => row?.table_name,
        },
        {
          name: 'Created At',
          // width: '140px',
          cell: row => row?.created_at,
        },
      ];
      setColumns(fetchColumns);

      setBookingData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props?.pageNumber * props?.limit + index + 1 - props?.limit}.`,
            name: data?.name,
            table_name: data?.table_name,
            created_at: data?.created_at ? moment(new Date(`${data?.created_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            originalData: data,
          };
        }),
      );
    } else {
      setBookingData([]);
      setColumns([]);
    }
  }, [props?.data]);

  return (
    <>
      {isCreateRuleModal && (
        <CreateRuleModal
          isLoading={createSurgeRuleLoading}
          onClose={() => {
            setIsCreateRuleModal(false);
          }}
        />
      )}

      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <h2 className="fw-semibold card-title mb-0 flex-grow-1">All Rules</h2>
              <button type="button" className="btn btn-success waves-effect waves-light" onClick={() => setIsCreateRuleModal(true)}>
                Create Rule
              </button>
            </CardHeader>

            <CardBody>
              <CustomTable
                columns={columns ?? []}
                data={bookingData ?? []}
                totalCount={props.totalCount}
                limit={props.limit}
                handleRowsPerPageChange={props.handleRowsPerPageChange} // Allows rows-per-page customization
                currentPage={props.pageNumber}
                handlePageChange={props.handlePageChange}
                indexType={Constant.INDEX_TYPE.ONE_BASED}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
