import { useEffect, useState } from 'react';

import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import { formattedWithComma, hasPermission } from '../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import moment from 'moment';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Name',
          selector: row => row?.name,
          wrap: true,
          width: '200px',
        },
        {
          name: 'Email',
          selector: row => row?.email,
          wrap: true,
          minWidth: '250px',
        },
        {
          name: 'Mobile',
          selector: row => row?.phoneNumber,
          wrap: true,
          width: '180px',
        },

        {
          name: 'City',
          selector: row => row?.city,
          wrap: true,
          width: '180px',
        },

        {
          name: 'Register At',
          selector: row => row?.registerAt,
          wrap: true,
          width: '180px',
        },

        {
          name: 'Actions',
          width: '180px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100 my-2">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPDATE_CUSTOMER) && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`UPDATE`, row?.data);
                  }}
                >
                  Update Details
                </span>
              )}

              {JSON.parse(localStorage.getItem('data'))?.program?.configuration?.payment?.cardDirectory?.isActive && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`CARD_LIST`, row?.data);
                  }}
                >
                  Card List
                </span>
              )}

              {true && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    ['THRIWE_APP', 'HSBC_BEACH_CLUB', 'GLOBE_TOYOTA', 'HDFC_BUNDLE', 'MASTERCARD_CL', 'ENBD_NATIONAL_DAY', 'MASTERCARD_FAB'].includes(
                      Constant.PROJECT_CODE,
                    )
                      ? actionBtnHandler(`MEMBERSHIPS_THRIWE_APP`, row?.data)
                      : actionBtnHandler(`MEMBERSHIPS`, row?.data);
                  }}
                >
                  Memberships
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            name: `${data?.firstName ?? ''} ${data?.lastName ?? ''}` || ``,
            email: data?.email || ``,
            phoneNumber: `${data?.countryCode ? `+${data?.countryCode}-` : ''}${data?.phoneNumber ?? ''}` || ``,
            registerAt: data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            city: data?.addresses?.[0]?.subregion || 'NA',
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `CARD_LIST`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `MEMBERSHIPS`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `MEMBERSHIPS_THRIWE_APP`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{`Customers Data`}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1 mx-2" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i> Download Dump
                </span>
              )}

              <span className="btn btn-info mb-1" onClick={props?.filterBtnHandler}>
                <i className="ri-filter-3-line align-bottom me-1"></i>Filter
              </span>
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
