import { Container } from 'reactstrap';
import BreadcrumbTrail from '../../../../Common/BreadcrumbTrail';
import CreateSurgeWorkflowForm from './CreateSurgeWorkflow/Components/Form/Form';
import { useDispatch } from 'react-redux';

const SurgeWorkflow = () => {
  const dispatch = useDispatch();

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {};

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <BreadcrumbTrail title="Configure Workflow" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Configure Workflow`]} />

        <CreateSurgeWorkflowForm />
      </Container>
    </div>
  );
};
export default SurgeWorkflow;
