import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { GET_EVENTS_LIST, GET_EVENTS_LIST_EMPTY } from '../../../../../../store/actions';
import { Loader } from '../../../../../Common';
import BreadcrumbTrail from '../../../../../Common/BreadcrumbTrail';
import { Constant } from '../../../../../Helpers/constant';
import Table from './Components/Table/index';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Filter from './Components/Filter';

const SurgeEvent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Parse URL query parameters
  const { page = 1, perPageLimit = Constant.TABLE_PAGE_SIZE } = queryString.parse(location.search);

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(Number(perPageLimit));
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Event List
  const { listEvents, listEventsLoading } = useSelector((state: any) => ({
    listEvents: state?.ListSurgeEvents?.data,
    listEventsLoading: state?.ListSurgeEvents?.loading,
  }));

  useEffect(() => {
    const { page, perPageLimit } = queryString.parse(location.search);
    setCurrentPageNumber(Number(page || 1)); // Default to 1-based page
    setRowsPerPage(Number(perPageLimit || Constant.TABLE_PAGE_SIZE));
  }, [location.search]);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_EVENTS_LIST,
      payload: {
        urlParams: {
          skip: (currentPageNumber - 1) * rowsPerPage,
          limit: rowsPerPage,
          ...currentlyAppliedFilters,
        },
      },
    });

    // return () => {
    //   dispatch({
    //     type: GET_EVENTS_LIST_EMPTY,
    //   });
    // };
  }, [currentlyAppliedFilters, currentPageNumber, rowsPerPage, dispatch]);

  const handleRowsPerPageChange = (newRowsPerPage, page) => {
    updateURLParams({ page: 1, perPageLimit: newRowsPerPage });
  };

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);
    const updatedParams = {
      page: currentPageNumber,
      perPageLimit: rowsPerPage,
      ...currentParams,
      ...newParams,
    };

    const newUrl = queryString.stringify(updatedParams);

    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {};

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    updateURLParams({ page: pageNumber });
  };

  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {};

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (listEventsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [listEventsLoading]);

  return (
    <div className="page-content">
      {loading && <Loader />}

      <Container fluid className="position-relative">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
          <BreadcrumbTrail title="Event Management" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Event Management`]} />
          <Filter filterHandler={filterSubmitHandler} />
        </div>

        <Table
          data={listEvents?.results}
          totalCount={listEvents?.count}
          pageNumber={currentPageNumber}
          actionHandler={tableActionHandler}
          appliedFilters={() => {}}
          limit={perPageLimit}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handlePageChange={paginationHandler}
        />

        {/* <Summary /> */}
      </Container>
    </div>
  );
};

export default SurgeEvent;
