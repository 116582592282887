import { useEffect, useState } from 'react';

import axios from 'axios';
import Flatpickr from 'react-flatpickr';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { CREATE_SURGE_RULE_EMPTY, CREATE_SURGE_WORKFLOW, CREATE_SURGE_WORKFLOW_EMPTY } from '../../../../../../../../store/actions';
import { Error, Loader } from '../../../../../../../Common';
import { customHeaders, thriweDiscoveryUrl } from '../../../../../../../Config';
import { Constant } from '../../../../../../../Helpers/constant';
import CreateRuleModal from '../../../../Event/CreateEvent/Components/Modal';

const CreateSurgeWorkflowForm = () => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    trigger: {
      frequency: 0,
      start_time: '',
      end_time: '',
      isfrequencyEnabled: false,
    },
    events: [
      {
        id: null,
        expression: '',
        effects: [
          {
            effect: null,
            value: '',
          },
        ],

        event: null,
        rule: null,
      },
    ],
  };

  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isCreateRuleModal, setIsCreateRuleModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // CREATE SURGE RULE
  const { createSurgeRule, createSurgeRuleLoading } = useSelector(state => ({
    createSurgeRule: state?.CreateSurgeRule?.data,
    createSurgeRuleLoading: state?.CreateSurgeRule?.loading,
  }));

  // CREATE SURGE WORKFLOW
  const { createSurgeWorkflow, createSurgeRuleWorkflowLoading } = useSelector(state => ({
    createSurgeWorkflow: state?.CreateSurgeWorkflow?.data,
    createSurgeRuleWorkflowLoading: state?.CreateSurgeWorkflow?.loading,
  }));

  useEffect(() => {
    if (createSurgeRule !== null) {
      dispatch({
        type: CREATE_SURGE_RULE_EMPTY,
      });

      setIsCreateRuleModal(false);
    }
  }, [createSurgeRule]);

  useEffect(() => {
    if (createSurgeWorkflow !== null) {
      dispatch({
        type: CREATE_SURGE_WORKFLOW_EMPTY,
      });

      setState({ ...initialState });
    }
  }, [createSurgeWorkflow]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'effect-value':
        updatedState = {
          ...state,
          events: [
            {
              ...state?.events?.[0],
              effects: [
                {
                  ...state?.events?.[0]?.['effects']?.[0],
                  value: value,
                },
              ],
            },
          ],
        };
        break;

      case 'isfrequencyEnabled':
        updatedState = {
          ...state,
          trigger: {
            ...state?.trigger,
            isfrequencyEnabled: !state?.trigger?.isfrequencyEnabled,
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...state };

    switch (flag) {
      case 'start_time':
        updatedState = {
          ...state,
          trigger: {
            ...state?.trigger,
            start_time: new Date(date).toISOString(),
          },
        };
        break;

      case 'end_time':
        updatedState = {
          ...state,
          trigger: {
            ...state?.trigger,
            end_time: new Date(date).toISOString(),
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    switch (label) {
      case 'event':
        updatedState = {
          ...state,
          events: [
            {
              ...state?.events?.[0],
              event: data,
            },
          ],
        };
        break;

      case 'rule':
        updatedState = {
          ...state,
          events: [
            {
              ...state?.events?.[0],
              rule: data,
            },
          ],
        };
        break;

      case 'effect':
        updatedState = {
          ...state,
          events: [
            {
              ...state?.events?.[0],
              effects: [
                {
                  ...state?.events?.[0]?.['effects']?.[0],
                  effect: data,
                },
              ],
            },
          ],
        };
        break;

      case 'frequency':
        updatedState = {
          ...state,
          trigger: {
            ...state?.trigger,
            frequency: data,
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.events?.[0]?.event !== null &&
      data?.events?.[0]?.rule !== null &&
      data?.events?.[0]?.effects?.[0]?.effect !== null &&
      data?.events?.[0]?.effects?.[0]?.value?.trim() !== '' &&
      data?.trigger?.start_time?.trim() !== `` &&
      data?.trigger?.end_time?.trim() !== `` &&
      new Date(data?.trigger?.start_time?.trim()).getTime() < new Date(data?.trigger?.end_time?.trim()).getTime()
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        trigger: {
          frequency: parseInt(state?.trigger?.frequency ?? 0),
          start_time: `${state?.trigger?.start_time ?? ''}`,
          end_time: `${state?.trigger?.end_time ?? ''}`,
        },
        events: [
          {
            id: `${state?.events?.[0]?.event?.value}`,
            expression: `${state?.events?.[0]?.rule?.value}`,
            effects: [
              {
                effect_type_id: state?.events?.[0]?.effects?.[0]?.['effect']?.['value'],
                value: parseInt(state?.events?.[0]?.effects?.[0]?.['value'] ?? 0),
              },
            ],
          },
        ],
      };

      dispatch({
        type: CREATE_SURGE_WORKFLOW,
        payload: {
          data: {
            ...payload,
          },
        },
      });
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (createSurgeRuleWorkflowLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createSurgeRuleWorkflowLoading]);

  return (
    <>
      {isCreateRuleModal && (
        <CreateRuleModal
          isLoading={createSurgeRuleLoading}
          onClose={() => {
            setIsCreateRuleModal(false);
          }}
        />
      )}
      {loading && <Loader />}

      <Card className="card-inner">
        {/* <button onClick={() => console.log('hello get state', state)}>get State</button>
        <button onClick={() => setIsCreateRuleModal(true)}>Create rule</button> */}

        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Events</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <AsyncPaginate
                debounceTimeout={500}
                value={state?.events?.[0]?.event}
                loadOptions={loadSurgeEvents}
                onChange={event => inputSelectHandler(`event`, event)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder="Select Event"
              />
              {isValidationShow && state?.events?.[0]?.event === null && <Error text="Please select event" />}
            </Col>

            <Col md={4}>
              <div className="mb-3">
                <Flatpickr
                  className="form-control"
                  name="start_time"
                  id="start_time"
                  placeholder="Start Date"
                  value={state?.trigger?.start_time}
                  onChange={date => datePickHandler(`start_time`, date)}
                  options={{
                    minDate: 'today',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }}
                />
                {isValidationShow && state?.trigger?.start_time === `` && <Error text="Please select start date" />}

                {isValidationShow &&
                  state?.trigger?.start_time !== `` &&
                  state?.trigger?.end_time !== `` &&
                  new Date(state?.trigger?.start_time).getTime() > new Date(state?.trigger?.end_time).getTime() && (
                    <Error text="Start date is invalid" />
                  )}
              </div>
            </Col>

            <Col md={4}>
              <div className="mb-3">
                <Flatpickr
                  className="form-control"
                  name="end_time"
                  id="end_time"
                  placeholder="End Date"
                  value={state?.trigger?.end_time}
                  onChange={date => datePickHandler(`end_time`, date)}
                  options={{
                    minDate: 'today',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }}
                />
                {isValidationShow && state?.trigger?.end_time?.trim() === `` && <Error text="Please enter end date" />}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="card-inner">
        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Rules</h4>
          <button type="button" className="btn btn-success waves-effect waves-light" onClick={() => setIsCreateRuleModal(true)}>
            Create Rule
          </button>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm={4} className="mb-3">
              <AsyncPaginate
                debounceTimeout={500}
                value={state?.events?.[0]?.['rule']}
                loadOptions={loadSurgeRules}
                onChange={event => inputSelectHandler('rule', event)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder="Select Rule"
              />
              {isValidationShow && state?.events?.[0]?.rule === null && <Error text="Please select rule" />}
            </Col>

            <>
              <Col sm={4} className="mb-3">
                <Select
                  id="rule-table"
                  name="rule-table"
                  placeholder="Select Table"
                  value={
                    state?.events?.[0]?.['rule']
                      ? {
                          value: `${state?.events?.[0]?.['rule']?.['completeData']?.['table_name']}`,
                          label: `${state?.events?.[0]?.['rule']?.['completeData']?.['table_name']}`,
                        }
                      : null
                  }
                  isDisabled={true}
                />
              </Col>

              <Col sm={4} className="mb-3">
                <Select
                  id="rule-column"
                  name="rule-column"
                  placeholder="Select Column"
                  value={
                    state?.events?.[0]?.['rule']
                      ? {
                          value: `${state?.events?.[0]?.['rule']?.['completeData']?.['conditions']?.[0]?.['column_name']}`,
                          label: `${state?.events?.[0]?.['rule']?.['completeData']?.['conditions']?.[0]?.['column_name']}`,
                        }
                      : null
                  }
                  isDisabled={true}
                />
              </Col>

              <Col sm={4} className="mb-3">
                <Select
                  id="rule-operator"
                  name="rule-operator"
                  placeholder="Select where operator"
                  value={
                    state?.events?.[0]?.['rule']
                      ? {
                          value: `${state?.events?.[0]?.['rule']?.['completeData']?.['conditions']?.[0]?.['operator']}`,
                          label: `${state?.events?.[0]?.['rule']?.['completeData']?.['conditions']?.[0]?.['operator']}`,
                        }
                      : null
                  }
                  isDisabled={true}
                />
              </Col>

              <Col sm={4} className="mb-3">
                <Input
                  type="text"
                  id="rule-value"
                  name="rule-value"
                  placeholder="Value"
                  className="form-control"
                  value={state?.events?.[0]?.['rule'] ? `${state?.events?.[0]?.['rule']?.['completeData']?.['conditions']?.[0]?.['value']}` : ''}
                  disabled={true}
                />
              </Col>
            </>
          </Row>

          {/* <Row>
            <Col md={3}>
              <div className="mb-3">
                <div className="d-flex align-items-center ">
                  <span className="text-muted me-3">Aggregation allowed</span>
                  <div className="form-switch d-inline-block ">
                    <Input
                      className="form-check-input code-switcher form-switch-md cursor-pointer"
                      type="checkbox"
                      id="ruleOverride"
                      name="ruleOverride"
                      checked={state?.ruleOverride}
                      onChange={inputChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row> */}

          {/* <Row>
            <Col sm={4} className="mb-3">
              <Select
                id="rule-operator"
                name="rule-operator"
                placeholder="Choose field to group by"
                // value={state?.statusCode}
                // options={Constant?.campaignStatuses}
                // onChange={data => inputSelectHandler(`campaign-status`, data)}
              />
            </Col>
          </Row> */}
        </CardBody>
      </Card>

      <Card className="card-inner">
        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Frequency</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={3}>
              <div className="mb-3">
                <div className="d-flex align-items-center">
                  <span className="text-muted me-3">Frequency</span>
                  <div className="form-switch d-inline-block ">
                    <Input
                      className="form-check-input code-switcher form-switch-md cursor-pointer"
                      type="checkbox"
                      id="isfrequencyEnabled"
                      name="isfrequencyEnabled"
                      checked={state?.trigger?.isfrequencyEnabled}
                      onChange={inputChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Select
                id="frequency-unit"
                name="frequency-unit"
                value={{
                  value: 'Minutes',
                  label: 'Minutes',
                }}
                options={[
                  {
                    value: 'Minutes',
                    label: 'Minutes',
                  },
                ]}
                isDisabled={!state?.trigger?.isfrequencyEnabled}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <div className="my-3">
                <div className="d-flex align-items-center">
                  <span className="text-muted me-3">Every</span>
                  <div className="">
                    <select
                      className="form-control"
                      value={state?.trigger?.frequency}
                      onChange={e => inputSelectHandler(`frequency`, e.target.value)}
                      disabled={!state?.trigger?.isfrequencyEnabled}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="5">15</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </select>
                  </div>
                  <span className="text-muted ms-3">Minute(s)</span>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="card-inner">
        <CardHeader className="d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Effects Configuration</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <AsyncPaginate
                debounceTimeout={500}
                value={state?.events?.[0]?.['effects']?.[0]?.['effect']}
                loadOptions={loadSurgeEffects}
                onChange={event => inputSelectHandler(`effect`, event)}
                isMulti={false}
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'No results found'}
                cacheUniqs={[['code']]}
                placeholder="Select Effect"
              />
              {isValidationShow && state?.events?.[0]?.['effects']?.[0]?.['effect'] === null && <Error text="Please select effect" />}
            </Col>

            <Col sm={4} className="mb-3">
              <Input
                type="number"
                className="form-control"
                id="effect-value"
                name="effect-value"
                placeholder="Value"
                value={state?.events?.[0]?.['effects']?.[0]?.['value']}
                onChange={inputChangeHandler}
              />
              {isValidationShow && state?.events?.[0]?.['effects']?.[0]?.['value'] === '' && <Error text="Please enter effect value" />}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Col md={12} className="mb-5">
        <div className="text-end">
          <button type="submit" className="btn btn-primary btn-lg" onClick={submitHandler}>
            Create Workflow
          </button>
        </div>
      </Col>
    </>
  );
};

export default CreateSurgeWorkflowForm;

/* ---------------------------- GET EVENTS LIST  ---------------------------- */
async function loadSurgeEvents(search) {
  let urlParam = null;

  return await axios({
    method: `GET`,
    url: `${thriweDiscoveryUrl}/v1/admin/list-events`,
    headers: {
      Authorization: Constant?.CLIENT_TOKEN,
      ...customHeaders,
    },
    params: {
      ...urlParam,
      name: search === '' ? null : search,
      skip: 0,
      limit: 150,
    },
  })
    .then(function (response) {
      return {
        options: response?.data?.results?.map(data => {
          return {
            value: data?.id,
            label: `${data?.name}`,
            completeData: data,
          };
        }),
        hasMore: false,
      };
    })
    .catch(err => {
      return err;
    });
}

/* ---------------------------- GET RULES LIST  ---------------------------- */
async function loadSurgeRules(search) {
  let urlParam = null;

  return await axios({
    method: `GET`,
    url: `${thriweDiscoveryUrl}/v1/admin/list-rules`,
    headers: {
      Authorization: Constant?.CLIENT_TOKEN,
      ...customHeaders,
    },
    params: {
      ...urlParam,
      name: search === '' ? null : search,
      skip: 0,
      limit: 150,
    },
  })
    .then(function (response) {
      return {
        options: response?.data?.results?.map(data => {
          return {
            value: data?.rule_id,
            label: `${data?.name}`,
            completeData: data,
          };
        }),
        hasMore: false,
      };
    })
    .catch(err => {
      return err;
    });
}

/* ---------------------------- GET EFFECTS LIST  ---------------------------- */
async function loadSurgeEffects(search) {
  let urlParam = null;

  return await axios({
    method: `GET`,
    url: `${thriweDiscoveryUrl}/v1/admin/list-event-effects`,
    headers: {
      Authorization: Constant?.CLIENT_TOKEN,
      ...customHeaders,
    },
    params: {
      ...urlParam,
      name: search === '' ? null : search,
      skip: 0,
      limit: 150,
    },
  })
    .then(function (response) {
      return {
        options: response?.data?.results?.map(data => {
          return {
            value: data?.id,
            label: `${data?.name}`,
            completeData: data,
          };
        }),
        hasMore: false,
      };
    })
    .catch(err => {
      return err;
    });
}
