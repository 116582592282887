import DataTable from 'react-data-table-component';
import { Constant } from '../../Helpers/constant';

const CustomTable = ({
  columns = [],
  data = [],
  limit = 10,
  totalCount = 0,
  handleRowsPerPageChange,
  handlePageChange,
  currentPage = 1,
  indexType,
}) => {
  let displayedPage; // Declare displayedPage in the outer scope
  let onPageChange; // Declare onPageChange function in the outer scope

  if (indexType === Constant.INDEX_TYPE.ONE_BASED) {
    displayedPage = currentPage;

    // Define the onPageChange function
    onPageChange = page => {
      handlePageChange(page); // Adjust one-based (UI) to zero-based (API)
    };
  } else if (indexType === Constant.INDEX_TYPE.ZERO_BASED) {
    displayedPage = currentPage + 1;

    // Define the onPageChange function
    onPageChange = page => {
      handlePageChange(page - 1); // Adjust one-based (UI) to zero-based (API)
    };
  }
  return (
    <>
      <DataTable
        columns={columns ?? []}
        data={data ?? []}
        highlightOnHover
        pagination
        paginationServer
        onChangePage={onPageChange} // Keeps pagination logic as is
        fixedHeader
        fixedHeaderScrollHeight="400px" // Scrollable table body
        customStyles={{
          headRow: {
            style: {
              borderBottom: '1px solid #ddd',
              fontWeight: '600',
              backgroundColor: '#ced4da',
            },
          },
          cells: {
            style: {
              border: '0.5px solid #ddd',
              borderBottom: '0px solid #ddd',
            },
          },
          rows: {
            style: {
              minHeight: '70px', // Adjust row height
            },
          },
          pagination: {
            style: {
              borderTop: '1px solid #ddd',
              padding: '10px',
            },
          },
        }}
        paginationRowsPerPageOptions={[10, 15, 20]}
        paginationTotalRows={totalCount}
        paginationPerPage={limit}
        paginationDefaultPage={displayedPage} // Ensures one-based indexing for UI
        onChangeRowsPerPage={handleRowsPerPageChange} // Allows rows-per-page customization
      />
    </>
  );
};

export default CustomTable;
