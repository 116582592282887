import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { hasPermission } from '../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import { Constant } from '../../../../../Helpers/constant';
import CustomTable from '../../../../../Common/Table/Table';
import Flatpickr from 'react-flatpickr';

const Table = props => {
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serial_number,
        },
        {
          name: 'Customer ID',
          width: '200px',
          cell: row => row?.customer_id,
        },
        {
          name: 'Email',
          width: '200px',
          cell: row => row?.email,
        },
        {
          name: 'Mobile Number',
          width: '200px',
          cell: row => row?.mobile_number,
        },
        {
          name: 'Transaction ID',
          width: '320px',
          cell: row => row?.transaction_id,
        },
        {
          name: 'Particulars',
          width: '140px',
          cell: row => row?.description,
        },

        {
          name: 'Transaction Value',
          width: '150px',
          cell: row => row?.amount,
        },

        {
          name: 'Transaction Date & Time',
          width: '200px',
          cell: row => row?.transactionDate,
        },
      ];
      setColumns(fetchColumns);

      setData(
        bookingData?.map((data, index) => {
          return {
            serial_number: `${props?.limit * props?.pageNumber + (index + 1)}.`,
            customer_id: data?.user_id ? data?.user_id : 'NA',
            email: data?.email || 'NA',
            mobile_number: data?.mobile_number || 'NA',
            transaction_id: data?.transaction_id ? data?.transaction_id : `NA`,
            description: data?.description ? data?.description : 'NA',
            amount: data?.amount ? data?.amount : 'NA',
            referrer_email: data?.referrer_email ? data?.referrer_email : 'NA',
            referring_for: data?.referring_for ? data?.referring_for : 'NA',
            transactionDate: data?.timestamp ? moment(new Date(`${data?.timestamp}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
          };
        }),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [bookingData]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `APPROVE`) {
      props.actionHandler({
        referralDetails: data,
        actionType: flag,
      });
    } else if (flag === `REJECT`) {
      props.actionHandler({
        referralDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex justify-content-end">
              <h4 className="card-title mb-0 flex-grow-1">Ledger List</h4>
            </CardHeader>

            <CardBody>
              <CustomTable
                columns={columns ?? []}
                data={data ?? []}
                totalCount={props.totalCount}
                limit={props.limit}
                handleRowsPerPageChange={props.handleRowsPerPageChange} // Allows rows-per-page customization
                currentPage={props.pageNumber}
                handlePageChange={props.handlePageChange}
                indexType={Constant.INDEX_TYPE.ZERO_BASED}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
