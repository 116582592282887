import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweMarketPlaceUrl } from '../../../../../Containers/Config';
import { Constant } from '../../../../../Containers/Helpers/constant';
import { VALIDATE_VOUCHER, VALIDATE_VOUCHER_ERROR, VALIDATE_VOUCHER_SUCCESS } from '../../../actionType';
import apiCall from '../../../apiCall';

function* validateVoucher({ payload }) {
  console.log('payloaddd', payload);
  try {
    const response = yield call(apiCall, {
      url: `${thriweMarketPlaceUrl}/v1/admin/voucher-validation/bookings/${payload?.data?.bookingId}`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data?.verify,
    });

    if (response.status === 200) {
      yield put({
        type: VALIDATE_VOUCHER_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: VALIDATE_VOUCHER_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: VALIDATE_VOUCHER_ERROR, payload: error });
  }
}

export function* watchvalidateVoucher() {
  yield takeEvery(VALIDATE_VOUCHER, validateVoucher);
}

function* validateVoucherSaga() {
  yield all([fork(watchvalidateVoucher)]);
}

export default validateVoucherSaga;
