import React from 'react';
import { useHistory } from 'react-router-dom';

import { Card, Col, Container, Row, CardBody, Input, Label, CardHeader } from 'reactstrap';
import { BreadCrumb } from '../../../../Common';
import Select from 'react-select';
import { Constant } from '../../../../Helpers/constant';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CommunicationManagement = () => {
  const history = useHistory();

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Surge Sphere`) {
      // do nothing
    } else if (title === `Communication Management`) {
      // do nothing
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <BreadCrumb title="Ledger" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Surge Sphere`, `Communication Management`]} />

        <Card className="card-inner">
          <CardHeader className="d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Email Details</h4>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="validityTatValue" className="form-label">
                    Email Type
                  </Label>
                  <Select
                    id="validityTatUnit"
                    name="validityTatUnit"
                    // value={state?.validityTat?.unit}
                    options={[{ value: 'India', label: 'India' }]}
                    // onChange={(data) =>
                    //     inputSelectHandler(`validityTatUnit`, data)
                    // }
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="benefitGroupCost" className="form-label">
                    Subject Line
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="benefitGroupCost"
                    name="benefitGroupCost"
                    placeholder="Enter Subject"
                    //   value={state?.benefitGroupCost}
                    //   onChange={inputChangeHandler}
                  />
                </div>
              </Col>

              <Col sm={12}>
                <div className="mb-3">
                  <Label htmlFor="benefitGroupCost" className="form-label">
                    Content Body
                  </Label>
                  <CKEditor
                    editor={ClassicEditor}
                    // data={state?.shortDescription || ""}
                    config={{
                      toolbar: {
                        items: Constant.CkEditorToolbar,
                      },
                    }}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      // ckEditorDataInput(
                      //     `shortDescription`,
                      //     data
                      // );
                    }}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="benefitGroupCost" className="form-label">
                    Recipient List
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="benefitGroupCost"
                    name="benefitGroupCost"
                    value={`Prefetched`}
                    disabled
                    //   onChange={inputChangeHandler}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="benefitGroupCost" className="form-label">
                    Triggers
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="benefitGroupCost"
                    name="benefitGroupCost"
                    //   value={state?.benefitGroupCost}
                    //   onChange={inputChangeHandler}
                  />
                </div>
              </Col>

              <Col sm={6}>
                <div className="mb-3">
                  <Label htmlFor="validityTatValue" className="form-label">
                    Frequency
                  </Label>
                  <Select
                    id="validityTatUnit"
                    name="validityTatUnit"
                    // value={state?.validityTat?.unit}
                    options={[
                      { value: 'Draft', label: 'Draft' },
                      { value: 'Active', label: 'Active' },
                      { value: 'on Hold', label: 'on Hold' },
                      { value: 'Closed', label: 'Closed' },
                      { value: 'Cancelled', label: 'Cancelled' },
                    ]}
                    // onChange={(data) =>
                    //     inputSelectHandler(`validityTatUnit`, data)
                    // }
                  />
                </div>
              </Col>

              <Col md={12}>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    // onClick={submitHandler}
                    // disabled={isSubmitBtnDisabled}
                  >
                    Save Email
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        {/* <Col md={12} className="mb-5">
                    <div className="text-end">
                        <button
                            type="submit"
                            className="btn btn-primary"
                        // onClick={submitHandler}
                        // disabled={isSubmitBtnDisabled}
                        >
                            Create Campaign
                        </button>
                    </div>
                </Col> */}
      </Container>
    </div>
  );
};

export default CommunicationManagement;
