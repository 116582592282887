import React, { useEffect, useState } from 'react';
// import { BreadCrumb } from '../../Common';
import { BreadCrumb, Loader } from '../../../../Common';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import FileUploadCard from './Component/FileUploadCard/FileUploadCard';
import Table from './Component/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Constant } from '../../../../Helpers/constant';
import { GET_USER_REGISTRATION_LIST, GET_USER_REGISTRATION_LIST_EMPTY } from '../../../../../store/actions';
import BreadcrumbTrail from '../../../../Common/BreadcrumbTrail';
import queryString from 'query-string';
import { hasPermission } from '../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../Helpers/Constants/permissions';

const FileUpload = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Parse URL query parameters
  const { page = 1, perPageLimit = Constant.TABLE_PAGE_SIZE } = queryString.parse(location.search);

  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(Number(page) - 1); // `page` is 1-based
  const [rowsPerPage, setRowsPerPage] = useState(Number(perPageLimit));
  const [fileLogsType, setFileLogsType] = useState('USER_REGISTRATION');

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get File Logs
  const { getUserRegistration, getUserRegistrationLoading } = useSelector((state: any) => ({
    getUserRegistration: state?.GetUserRegistration?.data,
    getUserRegistrationLoading: state?.GetUserRegistration?.loading,
  }));

  const { uploadUserRegistration } = useSelector((state: any) => ({
    uploadUserRegistration: state?.UserRegistration?.data,
  }));

  useEffect(() => {
    // Sync state with URL
    const { page, perPageLimit } = queryString.parse(location.search);
    setCurrentPageNumber(Number(page || 1) - 1); // Default to 1-based page
    setRowsPerPage(Number(perPageLimit || Constant.TABLE_PAGE_SIZE));
  }, [location.search]);

  useEffect(() => {
    if (getUserRegistrationLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getUserRegistrationLoading]);

  useEffect(() => {
    {
      hasPermission(
        PermissionConstants.ALL_PERMISSIONS.USER_REGISTRATION &&
          dispatch({
            type: GET_USER_REGISTRATION_LIST,
            payload: {
              data: {
                type: fileLogsType,
                offset: currentPageNumber * rowsPerPage,
                limit: Number(perPageLimit),
              },
            },
          }),
      );
    }
  }, [fileLogsType, currentPageNumber, rowsPerPage, dispatch]);

  useEffect(() => {
    if (uploadUserRegistration !== null) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [uploadUserRegistration]);

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);
    console.log('currentParams', currentParams);
    const updatedParams = {
      page: currentPageNumber + 1, // Default to current page
      perPageLimit: rowsPerPage, // Default to current rowsPerPage
      ...currentParams, // Merge existing query parameters
      ...newParams, // Override with new parameters
    };

    const newUrl = queryString.stringify(updatedParams);

    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    updateURLParams({ page: pageNumber + 1 });
  };

  const handleRowsPerPageChange = newRowsPerPage => {
    updateURLParams({ page: 1, perPageLimit: newRowsPerPage });
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    }
  };

  const filterSubmitHandler = (data: { value: string; label: string }) => {
    setFileLogsType(data?.value);
    dispatch({
      type: GET_USER_REGISTRATION_LIST_EMPTY,
    });
    updateURLParams({ page: 1 });
  };

  return (
    <>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  UI'S ---------------------------- */}
          <BreadcrumbTrail title="File Upload" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `File Upload`]} />
          {/* <BreadCrumb title="File Upload" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'File Upload']} /> */}
          {(hasPermission(PermissionConstants.ALL_PERMISSIONS.USER_REGISTRATION) ||
            hasPermission(PermissionConstants.ALL_PERMISSIONS.EVENT) ||
            hasPermission(PermissionConstants.ALL_PERMISSIONS.TRANSACTION)) && (
            <>
              <FileUploadCard />{' '}
              <Table
                data={getUserRegistration?.data}
                totalCount={getUserRegistration?.data?.pagination?.total}
                pageNumber={currentPageNumber}
                limit={perPageLimit}
                handleRowsPerPageChange={handleRowsPerPageChange}
                handlePageChange={paginationHandler}
                fileLogsType={fileLogsType}
                filterHandler={filterSubmitHandler}
              />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default FileUpload;
