import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweMarketPlaceUaeUrl, thriweMarketPlaceUrl } from '../../../../../Containers/Config';
import { Constant } from '../../../../../Containers/Helpers/constant';
import apiCall from '../../../apiCall';
import { UPDATE_REFERRAL, UPDATE_REFERRAL_ERROR, UPDATE_REFERRAL_SUCCESS } from '../../../actionType';

function* validateReferral({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweMarketPlaceUrl}/v2/vendor/validate-referral/${payload?.urlParams?.id}`,
      method: 'PUT',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        // 'Project-Code': 'THRIWE_APP',
        ...customHeaders,
      },
      params: {
        status: payload?.urlParams?.status,
      },
    });

    if (response.status === 200) {
      yield put({
        type: UPDATE_REFERRAL_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: UPDATE_REFERRAL_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: UPDATE_REFERRAL_ERROR, payload: error });
  }
}

export function* watchValidateReferral() {
  yield takeEvery(UPDATE_REFERRAL, validateReferral);
}

function* validateReferralSaga() {
  yield all([fork(watchValidateReferral)]);
}

export default validateReferralSaga;
