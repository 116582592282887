import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweDiscoveryUrl } from '../../../../../Containers/Config/index.js';
import { Constant } from '../../../../../Containers/Helpers/constant.js';
import apiCall from '../../../apiCall';
import { GET_EFFECTS_LIST, GET_EFFECTS_LIST_ERROR, GET_EFFECTS_LIST_SUCCESS } from './../../../actionType.js';

function* getListSurgeEffects({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/list-event-effects`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_EFFECTS_LIST_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_EFFECTS_LIST_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_EFFECTS_LIST_ERROR, payload: error });
  }
}

export function* watchListSurgeEffects() {
  yield takeEvery(GET_EFFECTS_LIST, getListSurgeEffects);
}

function* listSurgeEffectsSaga() {
  yield all([fork(watchListSurgeEffects)]);
}

export default listSurgeEffectsSaga;
