import React, { useEffect, useState } from 'react';
import { Card, Row, Col, CardBody, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, CardFooter } from 'reactstrap';

import { Grid, _ } from 'gridjs-react';
import { Constant } from '../../../../../../../../Helpers/constant';
import ReactPaginate from 'react-paginate';
import moment from 'moment';

const EventsTable = ({ events, createNewEvent, totalData, pageHandler, pageNumber, actionHandler }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [eventsData, setEventsData] = useState([]);

  useEffect(() => {
    if (events?.length > 0) {
      setEventsData(
        events?.map((event, index) => {
          return [`${pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`, event?.eventName || `NA`, `${event?.eventId}` || `NA`, event, event];
        }),
      );
    } else {
      setEventsData([]);
    }
  }, [events]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `UPDATE`) {
      actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `DISABLE`) {
      actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `ENABLE`) {
      actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- STATUS COLOR HANDLER ---------------------------- */
  const statusColorHandler = status => {
    switch (status) {
      case true:
        return <span className="text-success">Active</span>;

      case false:
        return <span className="text-danger">Inactive</span>;

      default:
        return <span>Active</span>;
    }
  };

  return (
    <Card className="card-inner">
      <CardHeader className="d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Event List</h4>
        <button className="btn btn-primary" onClick={createNewEvent}>
          + Create New Event
        </button>
      </CardHeader>

      <CardBody>
        <Row>
          {/* <Col md={12} className='mb-4 border mb-3 pb-3 border-dashed border-end-0 border-start-0 border-top-0'>
                        <Row>
                            <Col md={4}>
                                <Label htmlFor="validityTatValue" className="form-label">
                                    Program Name
                                </Label>
                                <Select
                                    id="validityTatUnit"
                                    name="validityTatUnit"
                                    // value={state?.validityTat?.unit}
                                    options={[
                                        { value: "Draft", label: "Draft" },
                                        { value: "Active", label: "Active" },
                                        { value: "on Hold", label: "on Hold" },
                                        { value: "Closed", label: "Closed" },
                                        { value: "Cancelled", label: "Cancelled" },
                                    ]}
                                // onChange={(data) =>
                                //     inputSelectHandler(`validityTatUnit`, data)
                                // }
                                />
                            </Col>

                            <Col md={4} className='align-self-end'>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                >
                                    Apply
                                </button>
                            </Col>
                        </Row>
                    </Col> */}

          <Col md={12}>
            <div className="custom-striped-table">
              <Grid
                data={eventsData}
                columns={[
                  {
                    name: 'S.No.',
                    width: '50px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Event Name',
                    width: '100px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Event ID',
                    width: '100px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Status',
                    width: '80px',
                    formatter: cell => _(<span className="">{statusColorHandler(cell?.isActive)}</span>),
                  },
                  {
                    name: 'Action',
                    width: '130px',
                    formatter: cell =>
                      _(
                        <UncontrolledDropdown className="dropdown d-inline-block">
                          <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                            <i className="ri-more-fill align-middle"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <li
                              onClick={() => {
                                actionBtnHandler(`UPDATE`, cell);
                              }}
                            >
                              <DropdownItem className="edit-item-btn">
                                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                Edit Event
                              </DropdownItem>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li
                              onClick={() => {
                                actionBtnHandler(cell?.isActive ? `DISABLE` : `ENABLE`, cell);
                              }}
                            >
                              <DropdownItem className="remove-item-btn">
                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                {cell?.isActive ? `Disabled` : `Enabled`}
                              </DropdownItem>
                            </li>
                          </DropdownMenu>
                        </UncontrolledDropdown>,
                      ),
                  },
                ]}
                sort={true}
                // search={true}
                fixedHeader={true}
                height="400px"
                pagination={{ enabled: false, limit: 5 }}
                style={{
                  th: {
                    zIndex: '999',
                    textAlign: 'center',
                  },
                  td: {
                    position: 'relative',
                    textAlign: 'center',
                  },
                  li: {
                    color: 'blue',
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Row className="custom_pagination">
          <Col className="text-end">
            <ReactPaginate
              previousLabel={'previous'}
              forcePage={pageNumber}
              nextLabel={'next'}
              breakLabel={'...'}
              pageCount={Math.ceil(totalData / Constant.TABLE_PAGE_SIZE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={event => {
                pageHandler(event.selected);
              }}
              containerClassName={'pagination justify-content-center'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

// export default React.memo(
//     EventsTable,
//     (prevProps, nextProps) => prevProps.data === nextProps.data,
// )

export default EventsTable;
