import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  CardFooter,
} from 'reactstrap';

import ReactPaginate from 'react-paginate';
import { Grid, _ } from 'gridjs-react';
import moment from 'moment';
import Select from 'react-select';
import { Constant } from '../../../../../../Helpers/constant';

const RulesTable = ({ rules, createNewRule, totalData, pageHandler, pageNumber, actionHandler }) => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [rulesData, setRulesData] = useState([]);

  useEffect(() => {
    if (rules?.length > 0) {
      setRulesData(
        rules?.map((rule, index) => {
          return [
            `${pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            rule?.name || `NA`,
            rule?.campaignName || `NA`,
            rule?.programName || `NA`,
            rule?.trigger || `NA`,
            rule?.ruleStartDate ? moment(new Date(`${rule?.ruleStartDate}`)).format('DD-MMM-YYYY') : 'NA',
            rule?.ruleEndDate ? moment(new Date(`${rule?.ruleEndDate}`)).format('DD-MMM-YYYY') : 'NA',
            rule?.createdAt ? `${moment(new Date(`${rule?.createdAt}`)).format('DD-MMM-YYYY')}, ${rule?.createdBy}` : 'NA',
            rule,
            rule,
          ];
        }),
      );
    } else {
      setRulesData([]);
    }
  }, [rules]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `UPDATE`) {
      actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `DISABLE`) {
      actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `ENABLE`) {
      actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- STATUS COLOR HANDLER ---------------------------- */
  const statusColorHandler = status => {
    switch (status) {
      case true:
        return <span className="text-success">Active</span>;

      case false:
        return <span className="text-danger">Inactive</span>;

      default:
        return <span>Active</span>;
    }
  };

  return (
    <Card className="card-inner">
      <CardHeader className="d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Rule List</h4>
        <button className="btn btn-primary" onClick={createNewRule}>
          + Create New Rule
        </button>
      </CardHeader>

      <CardBody>
        <Row>
          {/* <Col
                        md={12}
                        className="mb-4 border mb-3 pb-3 border-dashed border-end-0 border-start-0 border-top-0"
                    >
                        <Row>
                            <Col md={4}>
                                <Label htmlFor="validityTatValue" className="form-label">
                                    Program Name
                                </Label>
                                <Select
                                    id="validityTatUnit"
                                    name="validityTatUnit"
                                    value={{
                                        value: Constant?.CURRENT_SELECTED_PROGRAM?.id,
                                        label: Constant?.CURRENT_SELECTED_PROGRAM?.name,
                                    }}
                                    isDisabled={true}
                                // options={}
                                // onChange={(data) =>
                                //     inputSelectHandler(`validityTatUnit`, data)
                                // }
                                />
                            </Col>

                            <Col md={4}>
                                <Label htmlFor="validityTatValue" className="form-label">
                                    Campaign Name
                                </Label>
                                <Select
                                    id="validityTatUnit"
                                    name="validityTatUnit"
                                    // value={state?.validityTat?.unit}
                                    options={[
                                        { value: "Draft", label: "Draft" },
                                        { value: "Active", label: "Active" },
                                        { value: "on Hold", label: "on Hold" },
                                        { value: "Closed", label: "Closed" },
                                        { value: "Cancelled", label: "Cancelled" },
                                    ]}
                                // onChange={(data) =>
                                //     inputSelectHandler(`validityTatUnit`, data)
                                // }
                                />
                            </Col>

                            <Col md={4} className="align-self-end">
                                <button type="button" className="btn btn-primary">
                                    Apply
                                </button>
                            </Col>
                        </Row>
                    </Col> */}

          <Col md={12}>
            <div className="custom-striped-table">
              <Grid
                data={rulesData}
                columns={[
                  {
                    name: 'S.No.',
                    width: '80px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Rule Name',
                    width: '130px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Campaign Name',
                    width: '160px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Program Name',
                    width: '150px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Rule Trigger Type',
                    width: '160px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Rule Start Date',
                    width: '130px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Rule End Date',
                    width: '130px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Created At & By',
                    width: '160px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Status',
                    width: '130px',
                    formatter: cell => _(<span className="">{statusColorHandler(cell?.isActive)}</span>),
                  },
                  {
                    name: 'Action',
                    width: '130px',
                    formatter: cell =>
                      _(
                        <UncontrolledDropdown className="dropdown d-inline-block">
                          <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                            <i className="ri-more-fill align-middle"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <li
                              onClick={() => {
                                actionBtnHandler(`UPDATE`, cell);
                              }}
                            >
                              <DropdownItem className="edit-item-btn">
                                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                Edit Rule
                              </DropdownItem>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li
                              onClick={() => {
                                actionBtnHandler(cell?.isActive ? `DISABLE` : `ENABLE`, cell);
                              }}
                            >
                              <DropdownItem className="remove-item-btn">
                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                {cell?.isActive ? `Disabled` : `Enabled`}
                              </DropdownItem>
                            </li>
                          </DropdownMenu>
                        </UncontrolledDropdown>,
                      ),
                  },
                ]}
                sort={true}
                // search={true}
                fixedHeader={true}
                height="400px"
                pagination={{ enabled: false, limit: 5 }}
                style={{
                  th: {
                    zIndex: '999',
                    textAlign: 'center',
                  },
                  td: {
                    position: 'relative',
                    textAlign: 'center',
                  },
                  li: {
                    color: 'blue',
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </CardBody>

      <CardFooter>
        <Row className="custom_pagination">
          <Col className="text-end">
            <ReactPaginate
              previousLabel={'previous'}
              forcePage={pageNumber}
              nextLabel={'next'}
              breakLabel={'...'}
              pageCount={Math.ceil(totalData / Constant.TABLE_PAGE_SIZE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={event => {
                pageHandler(event.selected);
              }}
              containerClassName={'pagination justify-content-center'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default RulesTable;
