import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweDiscoveryUrl, thriweMarketPlaceUaeUrl, thriweMarketPlaceUrl } from '../../../../../Containers/Config';
import { Constant } from '../../../../../Containers/Helpers/constant';
import apiCall from '../../../apiCall';
import { GET_SAMPLE_FILE_LIST, GET_SAMPLE_FILE_LIST_SUCCESS, GET_SAMPLE_FILE_LIST_ERROR } from '../../../actionType';

function* getSampleDownloadFile({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${thriweDiscoveryUrl}/v1/admin/data-integration/samplefile`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
      data: payload?.data,
      // data: {
      //   type: 'USER_REGISTRATION',

      //   start_date: '01-01-2024',

      //   end_date: '11-12-2024',
      //   offset: 0,

      //   limit: 1,
      //   order_by: 'desc',
      //   // pageSize: Constant?.TABLE_PAGE_SIZE,
      // },
    });

    if (response.status === 200) {
      yield put({
        type: GET_SAMPLE_FILE_LIST_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_SAMPLE_FILE_LIST_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_SAMPLE_FILE_LIST_ERROR, payload: error });
  }
}

export function* watchGetSampleFileDownload() {
  yield takeEvery(GET_SAMPLE_FILE_LIST, getSampleDownloadFile);
}

function* getDownloadSampleFile() {
  yield all([fork(watchGetSampleFileDownload)]);
}

export default getDownloadSampleFile;
