import React, { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { hasPermission } from '../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import { Constant } from '../../../../../Helpers/constant';
import CustomTable from '../../../../../Common/Table/Table';
import Flatpickr from 'react-flatpickr';

const initialState = {
  from_date: '',
  to_date: '',
};

const Table = props => {
  console.log('props change', props);
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filter, setFilter] = useState(initialState);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serial_number,
        },
        {
          name: 'Customer Name',
          width: '140px',
          selector: row => row?.name,
        },
        {
          name: 'Email',
          width: '200px',
          cell: row => row?.email,
        },
        {
          name: 'Mobile No.',
          width: '140px',
          cell: row => row?.mobile_no,
        },
        {
          name: 'Referred Name',
          width: '140px',
          cell: row => row?.referrer_name,
        },

        {
          name: 'Referred Email',
          width: '250px',
          cell: row => row?.referrer_email,
        },

        {
          name: 'Referred For',
          width: '130px',
          cell: row => row?.referring_for,
        },

        {
          name: 'Status',
          width: '150px',
          cell: row => <span>{row?.status}</span>,
        },
        {
          name: 'Actions',
          width: '220px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column m-4">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.VALIDATE_REFERRAL) && (
                <>
                  {row?.status === Constant?.REFERRAL_STATUS?.PENDING && (
                    <>
                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`APPROVE`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                      >
                        Approve
                      </button>
                      <button
                        className="btn btn-sm btn-danger mb-1"
                        onClick={() => {
                          actionBtnHandler(`REJECT`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                      >
                        Reject
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);

      setData(
        bookingData?.map((data, index) => {
          console.log('check number', props?.pageNumber * props?.limit + index - props?.limit);
          return {
            serial_number: `${props?.pageNumber * props?.limit + index + 1 - props?.limit}.`,
            name: data?.first_name || data?.last_name ? `${data?.first_name || ''} ${data?.last_name || ''}` : 'NA',
            mobile_no: data?.mobile_number ? data?.mobile_number : 'NA',
            email: data?.email_id ? data?.email_id : 'NA',
            referrer_name: data?.referrer_name ? data?.referrer_name : 'NA',
            referrer_email: data?.referrer_email ? data?.referrer_email : 'NA',
            referring_for: data?.referring_for ? data?.referring_for : 'NA',
            status: data?.status,
            created_at: data?.created_at ? moment(new Date(`${data?.created_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            updated_at: data?.updated_at ? moment(new Date(`${data?.updated_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            originalData: data,
          };
        }),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [bookingData]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `APPROVE`) {
      props.actionHandler({
        referralDetails: data,
        actionType: flag,
      });
    } else if (flag === `REJECT`) {
      props.actionHandler({
        referralDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex justify-content-end">
              <h4 className="card-title mb-0 flex-grow-1">Referral List</h4>
            </CardHeader>

            <CardBody>
              <CustomTable
                columns={columns ?? []}
                data={data ?? []}
                totalCount={props.totalCount}
                limit={props.limit}
                handleRowsPerPageChange={props.handleRowsPerPageChange} // Allows rows-per-page customization
                currentPage={props.pageNumber}
                handlePageChange={props.handlePageChange}
                indexType={Constant.INDEX_TYPE.ONE_BASED}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
