import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap';
import { CREATE_SURGE_RULE, LIST_RULE_TABLES } from '../../../../../../../../store/actions';
import { Error } from '../../../../../../../Common';
import Loader from '../../../../../../../../Components/Common/Loader';

const CreateRuleModal = ({ isLoading, onClose }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: '',
    table: '',
    where: [
      {
        operator: '',
        column: '',
        value: '',
      },
    ],
    select_variables: [],
    // aggregator: [
    //   {
    //     type: 'COUNT',
    //     operator: '>',
    //     value: '2',
    //     column: '', // if empty distinct will be primary_id of the table,
    //   },
    // ],
    // select_variables: ['USER_ID'],
  };

  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [columnsList, setColumnsList] = useState([]);
  const [operatorsList, setOperatorsList] = useState([]);
  const [variablesList, setVariablesList] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // GET SURGE RULE TABLES
  const { surgeRuleTables, surgeRuleTablesLoading } = useSelector((state: any) => ({
    surgeRuleTables: state?.ListSurgeRuleTables?.data,
    surgeRuleTablesLoading: state?.ListSurgeRuleTables?.loading,
  }));

  useEffect(() => {
    dispatch({
      type: LIST_RULE_TABLES,
    });
  }, []);

  const createRuleHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        name: `${state?.name ?? ''}`,
        table: `${state?.table?.['value'] ?? ''}`,
        where: [
          {
            operator: `${state?.where?.[0]?.operator?.['value']}`,
            column: `${state?.where?.[0]?.column?.['value']}`,
            value: state?.where?.[0]?.value ?? '0',
          },
        ],
        select_variables: state?.select_variables?.map(variable => variable?.value),
      };

      dispatch({
        type: CREATE_SURGE_RULE,
        payload: {
          data: payload,
        },
      });
    }
  };

  console.log('surgeRuleTables', surgeRuleTables);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        break;

      case 'rule-value':
        updatedState = {
          ...state,
          where: [
            {
              ...state?.where?.[0],
              value: value,
            },
          ],
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    switch (label) {
      case 'table':
        updatedState = {
          ...state,
          table: data,
          where: [
            {
              column: '',
              operator: '',
              value: '',
            },
          ],
          select_variables: [],
        };

        setColumnsList(
          data?.completeData?.columns?.map(columnData => ({
            value: `${columnData?.column_name}`,
            label: `${columnData?.column_name}`,
            completeData: columnData,
          })),
        );

        setVariablesList(
          data?.completeData?.columns
            ?.filter(c => c?.is_select_column)
            ?.map(columnData => ({
              value: `${columnData?.column_name}`,
              label: `${columnData?.column_name}`,
              completeData: columnData,
            })),
        );
        break;

      case 'column':
        updatedState = {
          ...state,
          where: [
            {
              ...state?.where?.[0],
              column: data,
              operator: '',
              value: '',
            },
          ],
        };

        setOperatorsList(
          data?.completeData?.where_operators?.map(operatorData => ({
            value: `${operatorData}`,
            label: `${operatorData}`,
            completeData: operatorData,
          })),
        );
        break;

      case 'operator':
        updatedState = {
          ...state,
          where: [
            {
              ...state?.where?.[0],
              operator: data,
              value: '',
            },
          ],
        };
        break;

      case 'variables':
        updatedState = {
          ...state,
          select_variables: [data],
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      state?.name?.trim() !== `` &&
      state?.table !== `` &&
      state?.where?.[0]?.operator !== `` &&
      state?.where?.[0]?.column !== `` &&
      state?.where?.[0]?.value !== `` &&
      state?.select_variables?.length !== 0
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  return (
    <>
      <Modal isOpen={true} size="lg" centered>
        <div className="modal-header p-3 bg-soft-primary">
          <h5 className="modal-title">Create Rule</h5>
          <Button type="button" onClick={() => onClose()} className="btn-close btn-light" aria-label="Close"></Button>
        </div>

        <ModalBody>
          {/* <button onClick={() => console.log('stateee', state, columnsList)}>get state</button> */}
          <Row>
            <Col sm={4} className="mb-3">
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Enter Rule Name"
                className="form-control"
                value={state?.name}
                onChange={inputChangeHandler}
              />
              {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter rule name" />}
            </Col>

            <Col sm={4} className="mb-3">
              <Select
                id="table"
                name="table"
                placeholder="Select Table"
                value={state?.table}
                options={surgeRuleTables?.results?.map(tableData => ({
                  value: `${tableData?.name}`,
                  label: `${tableData?.name}`,
                  completeData: tableData,
                }))}
                onChange={data => inputSelectHandler(`table`, data)}
              />
              {isValidationShow && state?.table === `` && <Error text="Please select table" />}
            </Col>

            <Col sm={4} className="mb-3">
              <Select
                id="column"
                name="column"
                placeholder="Select Column"
                value={state?.where?.[0]?.column}
                options={columnsList}
                onChange={data => inputSelectHandler(`column`, data)}
                isDisabled={state?.table === ``}
              />
              {isValidationShow && state?.where?.[0]?.column === `` && <Error text="Please select column" />}
            </Col>

            <Col sm={4} className="mb-3">
              <Select
                id="operator"
                name="operator"
                placeholder="Select where operator"
                value={state?.where?.[0]?.operator}
                options={operatorsList}
                onChange={data => inputSelectHandler(`operator`, data)}
                isDisabled={state?.where?.[0]?.column === ``}
              />
              {isValidationShow && state?.where?.[0]?.operator === `` && <Error text="Please select operator" />}
            </Col>

            <Col sm={4} className="mb-3">
              <Input
                type="number"
                id="rule-value"
                name="rule-value"
                placeholder="Value"
                className="form-control"
                value={state?.where?.[0]?.value}
                onChange={inputChangeHandler}
                isDisabled={state?.where?.[0]?.operator === ``}
              />
              {isValidationShow && state?.where?.[0]?.value === `` && <Error text="Please enter value" />}
            </Col>
          </Row>

          <Row>
            <Col sm={4} className="mb-3">
              <Select
                id="variables"
                name="variables"
                placeholder="Select variable"
                value={state?.select_variables}
                options={variablesList}
                // isMulti={true}
                onChange={data => inputSelectHandler(`variables`, data)}
                isDisabled={state?.table === ``}
              />
              {isValidationShow && state?.select_variables?.length === 0 && <Error text="Please select variables" />}
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter className="d-flex justify-content-start">
          <Button color="primary" className="btn-load">
            <span
              className="d-flex align-items-center"
              onClick={() => {
                !isLoading && createRuleHandler();
              }}
            >
              <span className="flex-grow-1 me-2">Create Rule</span>
              {isLoading && (
                <Spinner size="sm" className="flex-shrink-0">
                  Loading...
                </Spinner>
              )}
            </span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateRuleModal;

// const surgeRuleTables = {
//   results: [
//     {
//       name: 'users',
//       columns: [
//         {
//           column_name: 'age',
//           data_type: 'number',
//           where_operators: ['>=', '>', '<', '<=', '='],
//           aggregate_operators: ['SUM', 'AVG', 'COUNT'],
//           is_aggregate_column: true,
//           is_select_column: true,
//         },
//         {
//           column_name: 'created_at',
//           data_type: 'date',
//           where_operators: ['>=', '>', '<', '<=', '=', 'daterange'],
//           is_aggregate_column: false,
//         },
//         {
//           column_name: 'mobile_number',
//           data_type: 'string',
//         },
//         {
//           column_name: 'date_of_birth',
//           data_type: 'date',
//         },
//         {
//           column_name: 'wedding_anniversary',
//           data_type: 'date',
//           where_operators: ['>=', '>', '<', '<=', '=', 'daterange'],
//           is_aggregate_column: false,
//         },
//         {
//           column_name: 'car_anniversary',
//           data_type: 'date',
//           where_operators: ['>=', '>', '<', '<=', '=', 'daterange'],
//           is_aggregate_column: false,
//           is_select_column: true,
//         },
//       ],
//     },
//     {
//       name: 'transactions',
//       columns: [
//         {
//           column_name: 'points',
//           data_type: 'number',
//         },
//         {
//           column_name: 'service_id',
//           data_type: 'string',
//         },
//         {
//           column_name: 'amount',
//           data_type: 'number',
//         },
//         {
//           column_name: 'kilometers',
//           data_type: 'number',
//           where_operators: ['>='],
//           aggregate_operators: [],
//           is_aggregate_column: true,
//         },
//       ],
//     },
//   ],
// };
