import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { customHeaders, thriweDiscoveryUrl, thriweMarketPlaceUrl } from '../../../../../Containers/Config';
import { Constant } from '../../../../../Containers/Helpers/constant';
import { GET_VOUCHER_BOOKING_DETAILS, GET_VOUCHER_BOOKING_DETAILS_ERROR, GET_VOUCHER_BOOKING_DETAILS_SUCCESS } from '../../../actionType';
import apiCall from '../../../apiCall';

function* getVoucherBookingDetails({ payload }) {
  console.log('payloaddd', payload);
  try {
    const response = yield call(apiCall, {
      url: `${thriweMarketPlaceUrl}/v1/admin/voucher-validation/bookings/${payload?.data?.bookingId}`,
      method: 'GET',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_VOUCHER_BOOKING_DETAILS_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_VOUCHER_BOOKING_DETAILS_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_VOUCHER_BOOKING_DETAILS_ERROR, payload: error });
  }
}

export function* watchgetVoucherBookingDetails() {
  yield takeEvery(GET_VOUCHER_BOOKING_DETAILS, getVoucherBookingDetails);
}

function* getVoucherBookingDetailsSaga() {
  yield all([fork(watchgetVoucherBookingDetails)]);
}

export default getVoucherBookingDetailsSaga;
