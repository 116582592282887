import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { formattedWithComma, hasPermission, isDateIsGreaterThanLast7Days, isDatePassed } from '../../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../../Helpers/Constants/permissions';
import { TablePagination } from '../../../../../../../Common';
import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';
import { Constant } from '../../../../../../../Helpers/constant';

const Table = props => {
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));
    console.log('props?.data', props?.data);
    if (props?.data?.length > 0) {
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking };
        }),
      );
    } else {
      setData([]);
      setBookingData([]);
    }
  }, [props?.data]);

  console.log('Booking Data', bookingData);

  useEffect(() => {
    if (bookingData?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serialNumber,
        },
        {
          name: 'Booking Id',
          width: '140px',
          cell: row => (
            <span className="" style={{ color: '#3577f1' }}>
              {row?.booking_id}
            </span>
          ),
        },
        {
          name: 'Membership Id',
          width: '160px',
          cell: row => row?.membership_id,
        },
        {
          name: 'User Id',
          width: '250px',
          cell: row => row?.user_id,
        },
        {
          name: 'Full Name',
          width: '200px',
          cell: row => row?.name,
        },
        {
          name: 'Mobile No.',
          width: '140px',
          cell: row => row?.mobileNo,
        },

        {
          name: 'Email',
          width: '200px',
          cell: row => row?.email,
        },

        {
          name: 'Facility Name',
          width: '200px',
          cell: row => row?.facilityName,
        },

        {
          name: 'Partner',
          width: '200px',
          cell: row => row?.partnerName,
        },

        {
          name: 'Service',
          width: '200px',
          cell: row => row?.serviceName,
        },

        {
          name: 'Benefit Type',
          width: '160px',
          wrap: true,
          selector: row => row?.benefit_type,
        },
        {
          name: 'Booking Type',
          width: '190px',
          wrap: true,
          selector: row => row?.booking_type,
        },
        {
          name: 'Created At',
          width: '170px',
          wrap: true,
          selector: row => row?.created_at,
        },
        {
          name: 'Updated At',
          width: '170px',
          wrap: true,
          selector: row => row?.updated_at,
        },
        {
          name: 'Booking Status',
          width: '150px',
          cell: row => (
            <span
              className={`booking_status badge break-word bg-${
                Constant.BOOKING_STATUSES_V2.filter(status => status?.bookingStatus === row?.booking_status)[0]?.bgColor
              }`}
            >
              {row?.booking_status}
            </span>
          ),
        },
      ];
      setColumns(fetchColumns);

      setData(
        bookingData?.map((data, index) => {
          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + index + 1 - Constant.TABLE_PAGE_SIZE}.`,
            name: data?.user?.firstName || data?.user?.lastName ? `${data?.user?.firstName || ''} ${data?.user?.lastName || ''}` : 'NA',
            mobileNo: data?.user?.phoneNumber ? data?.user?.phoneNumber : 'NA',
            user_id: data?.user_id || 'NA',
            email: data?.user?.email ? data?.user?.email : 'NA',
            membership_id: data?.membership_id,
            facilityName: data?.booking_display?.benefitFacility?.name || 'NA',
            partnerName: data?.booking_display?.benefitPartner?.name || 'NA',
            serviceName: data?.booking_display?.displayName || 'NA',
            booking_id: data?.booking_id,
            benefit_type: data?.benefit_type,
            booking_type: data?.booking_type,
            booking_status: data?.booking_status,
            created_at: data?.created_at ? moment(new Date(`${data?.created_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            updated_at: data?.updated_at ? moment(new Date(`${data?.updated_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            originalData: data,
          };
        }),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [bookingData]);

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{currentFilteredBookingTitle}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )}
            </CardHeader>
            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma((props?.pageNumber - 1) * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma((props?.pageNumber - 1) * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber - 1} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
