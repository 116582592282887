import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CustomTable from '../../../../../../Common/Table/Table';
import { Constant } from '../../../../../../Helpers/constant';
import Select from 'react-select';
import moment from 'moment';
import { hasPermission } from '../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';

const fileUploadOptions = [
  {
    label: 'USER REGISTRATION',
    value: 'USER_REGISTRATION',
    permission: PermissionConstants.ALL_PERMISSIONS.USER_REGISTRATION,
  },
  {
    label: 'TRANSACTION',
    value: 'TRANSACTION',
    permission: PermissionConstants.ALL_PERMISSIONS.TRANSACTION,
  },
  {
    label: 'EVENT',
    value: 'EVENT',
    permission: PermissionConstants.ALL_PERMISSIONS.EVENT,
  },
];

const Table = props => {
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [columns, setColumns] = useState([]);

  const getDynamicPermissionOptions = () => {
    return fileUploadOptions.filter(option => hasPermission(option.permission));
  };

  console.log('getDynamicPermissionOptions', getDynamicPermissionOptions());

  useEffect(() => {
    if (props?.data?.files?.length > 0) {
      setBookingData(
        props?.data?.files?.map(booking => {
          return { ...booking };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serialNumber,
        },
        {
          name: 'File Name',
          width: '250px',
          cell: row => row?.fileName,
        },
        {
          name: 'Total Records',
          width: '180px',
          cell: row => row?.totalRecords,
        },
        {
          name: 'Success Record',
          width: '190px',
          cell: row => row?.successRecord,
        },
        {
          name: 'Failed Record',
          width: '190px',
          cell: row => row?.failRecord,
        },
        {
          name: 'Uploaded At',
          width: '190px',
          cell: row => row?.created_at,
        },
        {
          name: 'Status',
          width: '190px',
          cell: row => row?.status,
        },
      ];
      setColumns(fetchColumns);

      setData(
        bookingData?.map((data, index) => {
          return {
            serialNumber: `${props?.limit * props?.pageNumber + (index + 1)}.`,
            fileName: data?.file_name,
            totalRecords: data?.total_record,
            successRecord: data?.success_record,
            failRecord: data?.failed_record,
            created_at: data?.created_at ? moment(new Date(`${data?.created_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            status: data?.status,
          };
        }),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [bookingData]);

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">File Upload Logs</h4>
              <Select
                id="file-logs-type"
                name="file-logs-type"
                options={getDynamicPermissionOptions() ?? []}
                value={getDynamicPermissionOptions().find(option => option.value === props?.fileLogsType)}
                onChange={data => props?.filterHandler(data)}
                styles={{
                  menu: provided => ({
                    ...provided,
                    zIndex: 2,
                  }),
                  container: base => ({
                    ...base,
                    width: '160px',
                  }),
                }}
              />
            </CardHeader>

            <CardBody>
              <CustomTable
                columns={columns ?? []}
                data={data ?? []}
                totalCount={props.totalCount}
                limit={props.limit}
                handleRowsPerPageChange={props.handleRowsPerPageChange} // Allows rows-per-page customization
                currentPage={props.pageNumber}
                handlePageChange={props.handlePageChange}
                indexType={Constant.INDEX_TYPE.ZERO_BASED}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
