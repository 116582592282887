import React, { useEffect, useState } from 'react';
import { Loader } from '../../../../Common';
import { Container } from 'reactstrap';
import BreadcrumbTrail from '../../../../Common/BreadcrumbTrail';
import Filter from './Filters/Filter';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Constant } from '../../../../Helpers/constant';
import queryString from 'query-string';
import { GET_VOUCHER_BOOKING, GET_VOUCHER_BOOKING_DETAILS, GET_VOUCHER_BOOKING_EMPTY } from '../../../../../store/actions';
import Table from './Tables/Table';
import VerifyVoucher from './Modals/VerifyVoucher';
import Summary from './Summary';

const Voucher = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Parse URL query parameters
  const { page = 1, perPageLimit = Constant.TABLE_PAGE_SIZE } = queryString.parse(location.search);

  const [currentPageNumber, setCurrentPageNumber] = useState(Number(page) - 1); // `page` is 1-based
  const [rowsPerPage, setRowsPerPage] = useState(Number(perPageLimit));
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentSelectedVoucherDetails, setCurrentSelectedVoucherDetails] = useState(false);
  const [isVoucherModalOpen, setIsVoucherModalOpen] = useState(false);
  const [updateType, setUpdateType] = useState(null);

  // Voucher Booking
  const { getVoucherBooking, getVoucherBookingLoading } = useSelector((state: any) => ({
    getVoucherBooking: state?.GetVoucherBooking?.data,
    getVoucherBookingLoading: state?.GetVoucherBooking?.loading,
  }));

  // Validate Voucher
  const { validateVoucher } = useSelector((state: any) => ({
    validateVoucher: state?.ValidateVoucher?.data,
  }));

  // Voucher Details
  const { voucherBookingDetails, voucherBookingDetailsLoading } = useSelector((state: any) => ({
    voucherBookingDetails: state?.GetVoucherBookingDetails?.data,
    voucherBookingDetailsLoading: state?.GetVoucherBookingDetails?.loading,
  }));

  useEffect(() => {
    // Sync state with URL
    const { page, perPageLimit } = queryString.parse(location.search);
    setCurrentPageNumber(Number(page || 1) - 1); // Default to 1-based page
    setRowsPerPage(Number(perPageLimit || Constant.TABLE_PAGE_SIZE));
  }, [location.search]);

  useEffect(() => {
    // Focus on the Summary container when voucherBookingDetails is not null
    if (voucherBookingDetails) {
      const summaryCard = document.getElementById('summary-card');
      if (summaryCard) {
        summaryCard.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [voucherBookingDetails]);

  useEffect(() => {
    dispatch({
      type: GET_VOUCHER_BOOKING,
      payload: {
        urlParams: {
          skip: currentPageNumber * rowsPerPage,
          limit: rowsPerPage,
          ...currentlyAppliedFilters,
        },
      },
    });
  }, [validateVoucher, currentlyAppliedFilters, currentPageNumber, rowsPerPage, dispatch]);

  useEffect(() => {
    if (validateVoucher !== null) {
      dispatch({
        type: GET_VOUCHER_BOOKING_EMPTY,
      });
      updateURLParams({ page: 1 });
    }
  }, [validateVoucher]);

  useEffect(() => {
    if (getVoucherBookingLoading || voucherBookingDetailsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [getVoucherBookingLoading, voucherBookingDetailsLoading]);

  const updateURLParams = newParams => {
    const currentParams = queryString.parse(location.search);

    const updatedParams = {
      page: currentPageNumber + 1, // Default to current page
      perPageLimit: rowsPerPage, // Default to current rowsPerPage
      ...currentParams, // Merge existing query parameters
      ...newParams, // Override with new parameters
    };

    const newUrl = queryString.stringify(updatedParams);

    history.push({
      pathname: location.pathname,
      search: `?${newUrl}`,
    });
  };

  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);
    dispatch({
      type: GET_VOUCHER_BOOKING_EMPTY,
    });
    updateURLParams({ page: 1 });
  };

  const paginationHandler = pageNumber => {
    updateURLParams({ page: pageNumber + 1 });
  };

  const handleRowsPerPageChange = newRowsPerPage => {
    updateURLParams({ page: 1, perPageLimit: newRowsPerPage });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedVoucherDetails(params?.voucherDetails);
    console.log('checkingparam', params.actionType);
    if (params?.actionType === `VERIFY`) {
      setIsVoucherModalOpen(true);
      setUpdateType('Verify');
    } else if (params?.actionType === `VIEW`) {
      dispatch({
        type: GET_VOUCHER_BOOKING_DETAILS,
        payload: {
          data: {
            bookingId: params?.voucherDetails?.booking_id,
          },
        },
      });
    }
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    }
  };

  return (
    <>
      {loading && <Loader />}

      {isVoucherModalOpen && (
        <VerifyVoucher onCloseHandler={() => setIsVoucherModalOpen(false)} data={currentSelectedVoucherDetails} updateType={updateType} />
      )}
      <div className="page-content">
        <Container fluid className="position-relative">
          <div id="main-voucher-container">
            <BreadcrumbTrail title="Voucher Validation" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Voucher Validation`]} />
            <Filter filterHandler={filterSubmitHandler} />
          </div>

          <Table
            data={getVoucherBooking?.results}
            totalCount={getVoucherBooking?.count}
            pageNumber={currentPageNumber}
            appliedFilters={currentlyAppliedFilters}
            limit={rowsPerPage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handlePageChange={paginationHandler}
            actionBtnHandler={tableActionHandler}
          />

          {voucherBookingDetails && <Summary voucherDetails={voucherBookingDetails} />}
        </Container>
      </div>
    </>
  );
};

export default Voucher;
