import React, { useEffect, useState } from 'react';
import { Card, Row, Col, CardBody, CardHeader, Label, CardFooter } from 'reactstrap';

import { Grid, _ } from 'gridjs-react';
import moment from 'moment';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { Constant } from '../../../../../../Helpers/constant';

const CampaignsTable = ({ campaigns, createNewCampaign, totalData, pageHandler, pageNumber, actionHandler }) => {
  const [campaignsData, setCampaignsData] = useState([]);

  useEffect(() => {
    if (campaigns?.length > 0) {
      setCampaignsData(
        campaigns?.map((campaign, index) => {
          return [
            `${pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            campaign,
            campaign?.programName || `NA`,
            `${campaign?.noOfRules}` || `NA`,
            campaign?.startDate ? moment(new Date(`${campaign?.startDate}`)).format('DD-MMM-YYYY') : 'NA',
            campaign?.endDate ? moment(new Date(`${campaign?.endDate}`)).format('DD-MMM-YYYY') : 'NA',
            campaign?.createdAt ? `${moment(new Date(`${campaign?.createdAt}`)).format('DD-MMM-YYYY')}, ${campaign?.createdBy}` : 'NA',
            campaign?.statusCode,
          ];
        }),
      );
    } else {
      setCampaignsData([]);
    }
  }, [campaigns]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `UPDATE`) {
      actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- STATUS COLOR HANDLER ---------------------------- */
  const statusColorHandler = statusCode => {
    const status = Constant?.campaignStatuses?.filter(cStatus => parseInt(cStatus?.value) === statusCode)[0]?.label;

    switch (statusCode) {
      case 1:
        return <span className="text-primary">{status}</span>;

      case 2:
        return <span className="text-success">{status}</span>;

      case 3:
        return <span className="text-warning">{status}</span>;

      case 4:
        return <span className="text-danger">{status}</span>;

      case 5:
        return <span className="text-danger">{status}</span>;

      default:
        return <span>NA</span>;
    }
  };

  return (
    <Card className="card-inner">
      <CardHeader className="d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Campaign List</h4>
        <button className="btn btn-primary" onClick={createNewCampaign}>
          + Create New Campaign
        </button>
      </CardHeader>

      {/* <button onClick={() => console.log('campaign', campaigns, campaignsData)}>get state</button> */}

      <CardBody>
        <Row>
          <Col md={12} className="mb-4 border mb-3 pb-3 border-dashed border-end-0 border-start-0 border-top-0">
            <Row>
              <Col md={4}>
                <Label htmlFor="validityTatValue" className="form-label">
                  Program Name
                </Label>
                <Select
                  id="validityTatUnit"
                  name="validityTatUnit"
                  value={{
                    value: Constant?.CURRENT_SELECTED_PROGRAM?.id,
                    label: Constant?.CURRENT_SELECTED_PROGRAM?.name,
                  }}
                  isDisabled={true}
                  // options={}
                  // onChange={(data) =>
                  //     inputSelectHandler(`validityTatUnit`, data)
                  // }
                />
              </Col>

              <Col md={4} className="align-self-end">
                <button type="button" className="btn btn-primary" disabled>
                  Apply
                </button>
              </Col>
            </Row>
          </Col>

          <Col md={12}>
            <div className="custom-striped-table">
              <Grid
                data={campaignsData}
                columns={[
                  {
                    name: 'S.No.',
                    width: '80px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Campaign Name',
                    width: '200px',
                    formatter: cell =>
                      _(
                        <span
                          className="fw-semibold text-primary text-decoration-underline cursor-pointer"
                          onClick={() => {
                            actionBtnHandler(`UPDATE`, cell);
                          }}
                        >
                          {cell?.campaignName || `NA`}
                        </span>,
                      ),
                  },
                  {
                    name: 'Program Name',
                    width: '150px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'No. of Rules',
                    width: '150px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Campaign Start Date',
                    width: '180px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Campaign End Date',
                    width: '180px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Created At & By',
                    width: '180px',
                    formatter: cell => _(<span className="">{cell}</span>),
                  },
                  {
                    name: 'Status',
                    width: '120px',
                    formatter: cell => _(<span className="">{statusColorHandler(cell)}</span>),
                  },
                ]}
                sort={true}
                // search={true}
                fixedHeader={true}
                height="400px"
                pagination={{ enabled: false, limit: 5 }}
                style={{
                  th: {
                    zIndex: '999',
                    textAlign: 'center',
                  },
                  td: {
                    position: 'relative',
                    textAlign: 'center',
                  },
                  li: {
                    color: 'blue',
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </CardBody>

      <CardFooter>
        <Row className="custom_pagination">
          <Col className="text-end">
            <ReactPaginate
              previousLabel={'previous'}
              forcePage={pageNumber}
              nextLabel={'next'}
              breakLabel={'...'}
              pageCount={Math.ceil(totalData / Constant.TABLE_PAGE_SIZE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={event => {
                pageHandler(event.selected);
              }}
              containerClassName={'pagination justify-content-center'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              breakClassName={'page-item'}
              breakLinkClassName={'page-link'}
              activeClassName={'active'}
            />
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default CampaignsTable;

// export default React.memo(
//     CampaignsTable,
//     (prevProps, nextProps) => prevProps.data === nextProps.data
// );
