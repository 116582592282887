import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CustomTable from '../../../../../Common/Table/Table';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import { hasPermission } from '../../../../../Helpers/Helpers';
import { Constant } from '../../../../../Helpers/constant';
import moment from 'moment';

const Table = props => {
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serial_number,
        },
        {
          name: 'Booking ID',
          width: '150px',
          cell: row => (
            <span
              className="cursor-pointer text-decoration-underline"
              style={{ color: '#3577f1' }}
              onClick={() => actionBtnHandler(`VIEW`, row?.originalData)}
            >
              {row?.booking_id}
            </span>
          ),
        },
        {
          name: 'Customer Name',
          width: '220px',
          cell: row => row?.customer_name,
        },
        {
          name: 'Voucher Generated On',
          width: '180px',
          cell: row => row?.voucher_generated_on,
        },

        {
          name: 'Status',
          width: '150px',
          cell: row => row?.status,
        },

        {
          name: 'Benefit Name',
          width: '150px',
          cell: row => row?.benefit_name,
        },
        {
          name: 'Actions',
          width: '220px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column m-4">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.VALIDATE_VOUCHER) && (
                <>
                  {row?.originalData?.status === Constant.REFERRAL_STATUS?.GENERATED && (
                    <>
                      <button
                        className="btn btn-sm btn-primary mb-1"
                        onClick={() => {
                          actionBtnHandler(`VERIFY`, row?.originalData);
                        }}
                        style={{ width: '150px' }}
                      >
                        Verify Voucher
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);

      setData(
        bookingData?.map((data, index) => {
          const localDate = new Date(data?.voucher_generated_on + 'Z');
          // Convert to UTC ISO string
          const utcDateTime = localDate.toISOString();
          return {
            serial_number: `${props?.limit * props?.pageNumber + (index + 1)}.`,
            customer_name: data?.customer_name?.trim() ? data?.customer_name : 'NA',
            booking_id: data?.booking_id ? data?.booking_id : `NA`,
            voucher_generated_on: data?.voucher_generated_on ? moment(new Date(utcDateTime)).format('DD-MM-YYYY, h:mm A') : 'NA',
            status: data?.status ? data?.status : 'NA',
            benefit_name: data?.benefit_name ? data?.benefit_name : 'NA',
            originalData: data,
          };
        }),
      );
    } else {
      setData([]);
      setColumns([]);
    }
  }, [bookingData]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `VERIFY`) {
      props.actionBtnHandler({
        voucherDetails: data,
        actionType: flag,
      });
    } else if (flag === `VIEW`) {
      props.actionBtnHandler({
        voucherDetails: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex justify-content-end">
              <h4 className="card-title mb-0 flex-grow-1">VOUCHER LIST</h4>
            </CardHeader>

            <CardBody>
              <CustomTable
                columns={columns ?? []}
                data={data ?? []}
                totalCount={props.totalCount}
                limit={props.limit}
                handleRowsPerPageChange={props.handleRowsPerPageChange} // Allows rows-per-page customization
                currentPage={props.pageNumber}
                handlePageChange={props.handlePageChange}
                indexType={Constant.INDEX_TYPE.ZERO_BASED}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Table;
