import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, ModalBody, Spinner } from 'reactstrap';
import { UPDATE_REFERRAL, UPDATE_REFERRAL_EMPTY, VALIDATE_VOUCHER, VALIDATE_VOUCHER_EMPTY } from '../../../../../../store/actions';

// import { CONFIRM_BOOKING_V2, CONFIRM_BOOKING_V2_EMPTY, CANCEL_BOOKING_V2, CANCEL_BOOKING_V2_EMPTY } from '../../../../../../../../store/actions';

const VerifyVoucher = ({ data, onCloseHandler, updateType }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { validateVoucher, validateVoucherLoading } = useSelector((state: any) => ({
    validateVoucher: state?.ValidateVoucher?.data,
    validateVoucherLoading: state?.ValidateVoucher?.loading,
  }));

  useEffect(() => {
    if (validateVoucher !== null) {
      onCloseHandler();
      return () => {
        dispatch({
          type: VALIDATE_VOUCHER_EMPTY,
        });
      };
    }
  }, [validateVoucher, dispatch, onCloseHandler]);

  const handleYesClick = () => {
    dispatch({
      type: VALIDATE_VOUCHER,
      payload: {
        data: {
          bookingId: data?.booking_id,
          verify: {
            validate: updateType === 'Verify' ? true : false,
          },
        },
      },
    });
  };

  return (
    <Modal isOpen={true} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-2 mx-sm-2">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-1 mb-0">Are you sure you want to verify?</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <Button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseHandler}>
            Close
          </Button>
          {validateVoucherLoading ? (
            <Button color="primary" className="btn-load" outline>
              <span className="d-flex align-items-center">
                <Spinner size="sm" className="flex-shrink-0">
                  Loading...
                </Spinner>
                <span className="flex-grow-1 ms-2">Loading...</span>
              </span>
            </Button>
          ) : (
            <Button type="button" className="btn w-sm btn-primary" onClick={handleYesClick}>
              Sure
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VerifyVoucher;
